import React from "react";

import {
  Dialog,
  DialogContent,
  withStyles,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import axios from "axios";

const styles = (theme) => ({
  button: {
    margin: "15px",
  },
});

export default withStyles(styles)(
  class EditPricesPopUp extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.prices !== prevProps.prices) {
        this.defaultState = {
          mt: {
            Regular: this.props.prices[0].price,
            WholeWheat: this.props.prices[1].price,
            RoshReg: this.props.prices[2].price,
            RoshWW: this.props.prices[3].price,
            Broken: this.props.prices[4].price,
          },
          open: false,
          scroll: "paper",
        };
      }
    }
    defaultState = {
      mt: {
        Regular: this.props.prices[0].price,
        WholeWheat: this.props.prices[1].price,
        RoshReg: this.props.prices[2].price,
        RoshWW: this.props.prices[3].price,
        Broken: this.props.prices[4].price,
      },
      open: false,
      scroll: "paper",
    };
    state = this.defaultState;

    handleClickOpen = async () => {
      this.setState(this.defaultState);
      this.setState({ open: true });
    };

    handleClose = () => {
      this.setState({ open: false });
    };
    handleChange = (event) => {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState((prevState) => ({
        mt: {
          ...prevState.mt,
          [name]: value,
        },
      }));
    };

    canBeSubmitted = () => {
      return !Object.values(this.state.mt)
        .map((mt) => (mt ? true : false))
        .includes(true);
    };
    onSubmit = async (event) => {
      const { chaburah, order_id, matzahTypes } = this.props;
      event.preventDefault();
      const prices = matzahTypes.map((mt, i) => ({
        price: this.state.mt[mt.name.replace(/\s+/g, "")] || 0,
        matzah_type_id: mt.id,
      }));
      try {
        await Promise.all(
          prices.map((price, i) => {
            const id = this.props.prices[i].id;
            return axios.put(
              `/api/chaburos/${chaburah.id}/orders/${order_id}/prices/${id}`,
              price
            );
          })
        );
        const { data: newPrices } = await axios.get(
          `/api/chaburos/${chaburah.id}/orders/${order_id}/prices`
        );
        this.setState({ open: false });
        this.props.setPrices(newPrices);
      } catch (error) {
        console.log(error);
        alert("something went wrong", error);
      }
    };

    render() {
      const { matzahTypes, classes, totals, lineItems } = this.props;
      return (
        <React.Fragment>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleClickOpen}
          >
            Edit Prices
          </Button>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-describedby="alert-dialog-description"
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
          >
            <DialogContent id="alert-dialog-title">
              <form onSubmit={this.onSubmit}>
                <Typography
                  align="center"
                  variant="display1"
                  gutterBottom
                  color="textSecondary"
                  className={classes.header}
                >
                  Set Prices For{" "}
                  {this.props.chaburah.name +
                    " " +
                    this.props.chaburah.nickname}
                </Typography>
                {matzahTypes.map((mt, index) => {
                  return (totals.find((t) => t.matzah_type_id === mt.id) || {})
                    .amount !== 0 ||
                    (lineItems.find((t) => t.matzah_type_id === mt.id) || {}).amount !==
                      0 ? (
                    <TextField
                      key={mt.id}
                      autoFocus
                      name={mt.name.replace(/\s+/g, "")}
                      value={this.state.mt[mt.name.replace(/\s+/g, "")]}
                      label={mt.name}
                      margin="normal"
                      placeholder="Amount"
                      type="number"
                      onChange={this.handleChange}
                      fullWidth
                    />
                  ) : null;
                })}

                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={this.onSubmit}
                  disabled={this.canBeSubmitted()}
                >
                  Submit
                </Button>
              </form>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      );
    }
  }
);
