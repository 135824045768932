import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Card } from "@material-ui/core";

import OrderForm from "../components/OrderForm";
import OrderTable from "../components/OrderTable";
// import YearContext from "../components/YearContext";

const styles = (theme) => ({
  root: {
    minHeight: "100vh",
    margin: "100px auto",
  },
  orderCard: {
    height: "420px",
    padding: "20px",
    textAlign: "center",
  },
});

export default withStyles(styles)(
  class OrderPage extends React.Component {
    _isMounted = false;
    state = {
      chaburos: [],
      matzahTypes: [],
      orders: [],
    };

    async componentDidMount() {
      this._isMounted = true;
      const [
        { data: chaburos },
        { data: matzahTypes },
        { data: orders },
      ] = await Promise.all([
        axios.get("/api/chaburos"),
        axios.get("/api/matzah_types"),
        axios.get("/api/orders?status=RUNNING"),
      ]);
      if (this._isMounted) {
        this.setState({ chaburos, matzahTypes, orders });
      }
    }
    componentWillUnmount() {
      this._isMounted = false;
    }
    setOrders = async () => {
      const { data: orders } = await axios.get("/api/orders?status=RUNNING");
      this.setState({ orders });
    };

    render() {
      const { classes, user } = this.props;
      const { chaburos, matzahTypes, orders } = this.state;
      return (
        <Grid className={classes.root} container justify="center" spacing={16}>
          <Grid>
            <Card className={classes.orderCard}>
              <OrderForm
                chaburos={chaburos}
                matzahTypes={matzahTypes}
                setOrders={this.setOrders}
              />
            </Card>
          </Grid>
          <Grid>
            <Card>
              <OrderTable orders={orders} user={user} />
            </Card>
          </Grid>
        </Grid>
      );
    }
  }
);
