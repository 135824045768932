import React, { Component } from "react";
import { Grid, withStyles } from "@material-ui/core";
import axios from "axios";

import ChaburosPaymentsTable from "../components/ChaburosPaymentsTable";
import YearContext from "../components/YearContext";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100vh"
  }
});

export default withStyles(styles)(
  class ChaburosPaymentsPage extends Component {
    state = {
      chaburos: []
    };
    async componentWillMount() {
      this.getCaburos();
    }
    async componentDidUpdate(prevProps) {
      if (this.props.currentYear !== prevProps.currentYear) {
        this.getCaburos();
      }
    }
    async getCaburos() {
      const year = this.props.currentYear;
      const { data: chaburos } = await axios.get(
        "/api/chaburos?include=allorders&year=" +
          year +
          "&where=orders.paid&is=false"
      );

      this.setState({ chaburos });
    }

    static contextType = YearContext;
    render() {
      return (
        <div>
          <Grid container justify="center" className={this.props.classes.root}>
            <Grid item xs={9}>
              <ChaburosPaymentsTable chaburos={this.state.chaburos} />
            </Grid>
          </Grid>
        </div>
      );
    }
  }
);
