import React, { Component } from "react";
import { List, ListItem, ListItemText, withStyles } from "@material-ui/core";
const styles = theme => ({
  print: {
    width: "50%",
    textAlign: "center",
    margin: "auto",
    height: "50%",
    border: "3px solid black"
  }
});
export default withStyles(styles)(
  class Print extends Component {
    print = () => {};
    render() {
      const { classes } = this.props;
      const { matzohTaken, amountPaid, matzahTypes } = this.props;
      return (
        <div className={classes.print}>
          <List component="nav">
            <ListItem>
              <ListItemText primary="Matzoh Taken" />
            </ListItem>
            {matzohTaken
              .sort(function(a, b) {
                return a.matzah_type_id - b.matzah_type_id;
              })
              .map((matzohTaken, i) => (
                <React.Fragment key={matzohTaken.id}>
                  {matzohTaken.amount > 0 && (
                    <ListItem>
                      <ListItemText
                        primary={
                          matzahTypes[i].name + " " + matzohTaken.amount + "lb"
                        }
                      />
                    </ListItem>
                  )}
                </React.Fragment>
              ))}

            <ListItem>
              <ListItemText primary={"$" + amountPaid} />
            </ListItem>
          </List>
        </div>
      );
    }
  }
);
