import React, { Component } from "react";
import { Grid, withStyles } from "@material-ui/core";
import axios from "axios";
import YearContext from "../components/YearContext";
import PriceForm from "../components/PriceForm";
import TotalsTable from "../components/TotalsTable";
import PaymentsTable from "../components/PaymentsTable";
import ChaburahPaymentInfoTable from "../components/ChaburahPaymentInfoTable";
import { getTotals } from "../helpers/getTotals";
const styles = (theme) => ({
  root: {
    margin: "20px auto",
    maxWidth: "100%",
  },
});
export default withStyles(styles)(
  class PaymentsPage extends Component {
    state = {
      loading: true,
      loadingChaburahPaymentInfo: true,
      // loading1: true,
      chaburahPaymentInfoTableInfo: {},
      totalOwedOnItems: [],
      totalOwed: undefined,
      totals: [],
      prices: [],
      payments: [],
    };
    async componentDidMount() {
      const { chaburah_id, order_id } = this.props.match.params;
      const [
        {
          data: [chaburah],
        },
        { data: matzahTypes },
        { data: prices },
        { data: payments },
      ] = await Promise.all([
        axios.get(`/api/chaburos/${chaburah_id}`),
        axios.get("/api/matzah_types"),
        axios.get(`/api/chaburos/${chaburah_id}/orders/${order_id}/prices`),
        axios.get(`/api/chaburos/${chaburah_id}/orders/${order_id}/payments/`),
      ]);
      this.setState(
        {
          chaburah,
          matzahTypes,
          prices,
          payments,
          loading: false,
        },
        function () {
          if (!!prices.length) {
            this.getTableData();
          }
        }
      );
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        (this.state.prices !== prevState.prices && this.state.prices.length) ||
        this.state.prices.length !== prevState.prices.length ||
        this.state.payments.length !== prevState.payments.length
      ) {
        this.getTableData();
      }
    }
    getTotalPaid = (payments) => {
      const { prices } = this.state;
      console.log("payments");
      console.log(payments);
      console.log(prices);
      return payments
        .reduce((acc, payment) => [...acc, ...payment.payment_items], [])
        .reduce(
          (totals, paymentItem, i) => ({
            ...totals,
            [paymentItem.matzah_type_id]:
              (totals[paymentItem.matzah_type_id] || 0) +
              (paymentItem.amount *
                prices.find(
                  (price) => price.matzah_type_id === paymentItem.matzah_type_id
                ).price || 0),
          }),
          []
        );
    };
    getTotal = (items) => {
      return items.reduce((total, item) => {
        return total + item.amount;
      }, 0);
    };

    async getTableData() {
      const { prices, payments } = this.state;
      const { chaburah_id, order_id } = this.props.match.params;
      const results = await getTotals(chaburah_id, order_id);
      console.log("results");
      console.log(results.totals);
      console.log("prices");
      console.log(prices);
      this.setState({ totals: results.totals, lineItems: results.lineItems });
      const totalOwedOnItems = results.totals.slice().reduce(
        (totals, total, i) => [
          ...totals,
          {
            id: total.id,
            amount:
              (totals[total.id] || 0) + (total.amount * ((prices[i] || {}).price || 0)),
          },
        ],
        []
      );
      console.log("totalOwedOnItems");
      console.log(totalOwedOnItems);
      const totalOwed = this.getTotal(totalOwedOnItems);
      if (this.state.payments.length) {
        const objPaidOnItems = this.getTotalPaid(payments);
        console.log("objPaidOnItems");
        console.log(objPaidOnItems);
        const totalPaidOnItems = Object.entries(objPaidOnItems).map(
          ([id, amount]) => {
            return { id, amount };
          }
        );
        console.log("totalPaidOnItems");
        console.log(totalPaidOnItems);
        const totalPaid = this.getTotal(totalPaidOnItems);
        console.log("totalPaid");
        console.log(totalPaid);
        const outstandingOnItems = totalOwedOnItems.map((item, i) => ({
          id: item.id,
          amount: (item.amount || 0) - ((totalPaidOnItems[i] || {}).amount || 0),
        }));
        const outstandingOnMatzah = outstandingOnItems.map((item, i) => ({
          id: item.id,
          amount: (item.amount || 0) / (prices[i] || {}).price || 0,
        }));
        const outstanding = this.getTotal(outstandingOnItems);
        const amountPaid = payments.reduce((total, item) => {
          return total + item.payment_amount;
        }, 0);
        const amountDiscounted = payments.reduce((total, item) => {
          return total + item.discount;
        }, 0);
        var chaburahPaymentInfoTableInfo = {
          totalPaidOnItems,
          totalPaid,
          outstandingOnItems,
          outstanding,
          amountDiscounted,
          amountPaid,
          outstandingOnMatzah,
        };
        this.setState({
          chaburahPaymentInfoTableInfo,
        });
      }

      this.setState({
        totalOwedOnItems,
        totalOwed,
        loadingChaburahPaymentInfo: false,
      });
    }
    setPrices = (prices) => {
      this.setState({ prices });
    };
    setPayments = (payments) => {
      this.setState({ payments });
    };
    setPaymentsAndGetTableData = (payments) => {
      this.setState({ payments });
      this.getTableData();
    };
    static contextType = YearContext;
    render() {
      const {
        chaburah,
        matzahTypes,
        prices,
        payments,
        loadingChaburahPaymentInfo,
      } = this.state;
      const { order_id, chaburah_id } = this.props.match.params;
      if (this.state.loading) return <React.Fragment> </React.Fragment>;

      if (!prices.length)
        return (
          <Grid
            container
            justify="center"
            spacing={32}
            className={this.props.classes.root}
          >
            <Grid item xs={6}>
              <TotalsTable
                matzahTypes={matzahTypes}
                chaburah={chaburah}
                order_id={order_id}
              />
            </Grid>
            <Grid item xs={3}>
              <PriceForm
                matzahTypes={matzahTypes}
                chaburah={chaburah}
                order_id={order_id}
                setPrices={this.setPrices}
              />
            </Grid>
          </Grid>
        );
      //this should be done before its used in componant mount
      prices.sort(function (a, b) {
        return a.matzah_type_id - b.matzah_type_id;
      });
      if (!loadingChaburahPaymentInfo)
        return (
          <React.Fragment>
            <Grid
              container
              justify="center"
              className={this.props.classes.root}
            >
              <Grid item>
                <ChaburahPaymentInfoTable
                  chaburah={chaburah}
                  matzahTypes={matzahTypes}
                  chaburah_id={chaburah_id}
                  order_id={order_id}
                  prices={prices}
                  payments={payments}
                  setPayments={this.setPayments}
                  setPrices={this.setPrices}
                  totalOwedOnItems={this.state.totalOwedOnItems}
                  totalOwed={this.state.totalOwed}
                  totals={this.state.totals}
                  lineItems={this.state.lineItems}
                  amountDiscounted={this.state.amountDiscounted}
                  chaburahPaymentInfoTableInfo={
                    this.state.chaburahPaymentInfoTableInfo
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              className={this.props.classes.root}
            >
              {payments.length > 0 && (
                <Grid>
                  <PaymentsTable
                    matzahTypes={matzahTypes}
                    chaburah_id={chaburah_id}
                    order_id={order_id}
                    payments={payments}
                    prices={prices}
                    chaburah={chaburah}
                    setPaymentsAndGetTableData={this.setPaymentsAndGetTableData}
                    totalOwedOnItems={this.state.totalOwedOnItems}
                    totalOwed={this.state.totalOwed}
                    totals={this.state.totals}
                    chaburahPaymentInfoTableInfo={
                      this.state.chaburahPaymentInfoTableInfo
                    }
                  />
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        );
      return null;
    }
  }
);
