import React, { Component } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import axios from 'axios';
import DownloadPrint from '../../DownloadPrint';
import YearContext from "../../YearContext";
import ChaburahRuns from './ChaburahRunsSummary';
import ChaburahsTotals from './ChaburahTotals';
import ChaburahPayments from './ChaburahPaymentsSummaryPage';
import { CircularProgress } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        margin: '20px'
    },
    table: {
        width: '80%',
        margin: '0 auto'
    }
});

export default withStyles(styles)(
    class ChaburahListReports extends Component {
        state = {
            chaburos: [],
            currentYear: this.props.currentYear || new Date().getFullYear(),
            export: {
                filename: '', data: {
                    body: [], fields: []
                }
            },
            exportReady: false
        }
        static contextType = YearContext;
        async componentDidMount() {
            this.setState({ exportReady: false, currentYear: this.props.currentYear  });
            //await this.getChaburos();
        };

        async componentDidUpdate(prevProps) {
            if (this.props.currentYear !== prevProps.currentYear) {
                this.setState({ exportReady: false, currentYear: this.props.currentYear });
                //await this.getChaburos();
            }
        }

        

       /*  async getChaburos() {
            const year = this.props.currentYear;
            const [{ data: chaburos }] = await Promise.all([
                axios.get(
                    "/api/chaburos?include=orders&year=" +
                    year +
                    "&where=orders.status&is=COMPLETED"
                ),
            ]);
            let sorted = chaburos.sort((a, b) => a.name.localeCompare(b.name));
            this.setState({ currentYear: year, chaburos: sorted });
        } */

        setExportableData = (fields, mapped, report) => {  
            this.setState({ export: { filename: `Chaburah-${report}-Summary.csv`, data: { body: mapped, fields } } });
            this.setState({ exportReady: true });
        };

        render() {
            let download;
            let loader;
            if (this.state.exportReady) {
                download = <DownloadPrint diasabled='false' import={this.state.export} />;
                loader = <></>;
            } else {
                download = <DownloadPrint disabled='true' import={this.state.export} />;
                loader = <CircularProgress className={this.props.classes.progress} size={50} thickness={7} />;
            }

            let showPage;
            const {pathname} = this.props.location;
            if(pathname.includes('Runs')){
                showPage = <ChaburahRuns year = {this.state.currentYear} setExportableData={this.setExportableData} />
            }else if(pathname.includes('Payments')){
                showPage = <ChaburahPayments year = {this.state.currentYear} setExportableData={this.setExportableData} />
            }else if(pathname.includes('totals')){
                showPage = <ChaburahsTotals year = {this.state.currentYear} setExportableData={this.setExportableData} />
            }
            return (
                <div justify="center" className={this.props.classes.root}>
                    <br />
                    <Grid container 
                        justify="space-around"
                        alignItems="center"
                    >
                        <Grid item className='noPrint'>
                            {download}
                        </Grid>
                    </Grid>
                    <br />
                    <div className={this.props.classes.table}>
                        {showPage}
                    </div>
                    {loader}
                </div>
            )
        }
    }
)

