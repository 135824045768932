import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  withStyles,
  Button
} from "@material-ui/core";

const styles = theme => ({
  paymentLink: {
    textDecoration: "none"
  }
});

export default withStyles(styles)(
  class ChaburahTable extends Component {
    state = {
      currentYear: new Date().getFullYear()
    };
    render() {
      const { chaburos, classes } = this.props;

      return (
        <Card className={classes.card}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rosh chaburah</TableCell>
                <TableCell>Chaburah name</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {chaburos
                .sort(function(a, b) {
                  return (
                    new Date(b.orders[0].updated_at.substr(0, 10)) -
                    new Date(a.orders[0].updated_at.substr(0, 10))
                  );
                })
                .map(chaburah => (
                  <React.Fragment key={chaburah.id}>
                    {chaburah.orders.map(order => (
                      <TableRow key={order.id}>
                        <TableCell>{chaburah.name}</TableCell>
                        <TableCell>{chaburah.nickname}</TableCell>

                        <TableCell>
                          <Link
                            to={`/chaburos/${chaburah.id}/orders/${
                              order.id
                            }/payments`}
                            className={classes.paymentLink}
                          >
                            <Button color="primary" variant="outlined">
                              Pay It
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </Card>
      );
    }
  }
);
