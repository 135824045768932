import axios from "axios";

export const getTotals = async (chaburah_id, order_id) => {
  const [
    {
      data: [chaburah]
    },
    { data: matzahTypes },
    { data: lineItems },
    { data: runs }
  ] = await Promise.all([
    axios.get(`/api/chaburos/${chaburah_id}`),
    axios.get("/api/matzah_types"),
    axios.get(`/api/chaburos/${chaburah_id}/orders/${order_id}/line_items`),
    axios.get(`/api/chaburos/${chaburah_id}/orders/${order_id}/runs`)
  ]);

  const makeSortByMatzahType = matzahTypes => (a, b) =>
    matzahTypes.findIndex(mt => mt.id === a.matzah_type_id) -
    matzahTypes.findIndex(mt => mt.id === b.matzah_type_id);
  const getTotalPacked = runs => {
    return runs
      .reduce((acc, run) => [...acc, ...run.run_items], [])
      .reduce(
        (totals, runItem) => ({
          ...totals,
          [runItem.matzah_type_id]:
            (totals[runItem.matzah_type_id] || 0) + (runItem.amount || 0)
        }),
        {}
      );
  };
  const getTotalRemaining = (lineItems, totalPacked) =>
    lineItems.map(li => {
      const mt = totalPacked.find(mt => mt.id === li.matzah_type_id);
      return {
        ...mt,
        amount: (li.amount || 0) - (mt.amount || 0)
      };
    });
  const totalsObj = getTotalPacked(runs);
  const totals = matzahTypes.map(mt => ({
    ...mt,
    amount: totalsObj[mt.id] || 0,
    matzah_type_id: mt.id
  }));
  const sortByMatzahType = makeSortByMatzahType(matzahTypes);
  const sortedLineItems = lineItems.slice().sort(sortByMatzahType);
  const totalRemaining = getTotalRemaining(sortedLineItems, totals);
  return {
    totals,
    sortedLineItems,
    totalRemaining,
    chaburah,
    matzahTypes,
    lineItems,
    runs
  };
};
