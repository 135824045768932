import React, { Component } from "react";
import ChaburahPaymentInfoTable from "./ChaburahPaymentInfoTable";
import PaymentsTable2 from "../components/PaymentsTable2";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
export default class PrintReport extends Component {
    state = {
        open: false,
        scroll: "paper"
    };

    handleClickOpen = async () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    handlePrint = () => {
        const iframe = document.createElement('iframe');
        iframe.id = "iframetoprint";
        iframe.style = "height: 0px; width: 0px; position: absolute";
        document.head.appendChild(iframe);

        const content = document.getElementById("printDialog");
        const forPageBreak = document.getElementById("breakAfter");
        let breakSize;
        let topTable = parseFloat(getComputedStyle(forPageBreak).height);
        const tableWith1Type = 295;//actual is a little less
        const additionalType = 48;
        const origBreakSize = 690; //for table with 1 type, as in staging
        if (topTable < tableWith1Type) {
            breakSize = `${origBreakSize}px`;
        } else if (topTable < tableWith1Type + additionalType) {
            breakSize = `${origBreakSize - additionalType}px`;
        } else if (topTable < tableWith1Type + (additionalType * 2)) {
            breakSize = `${origBreakSize - (additionalType * 2)}px`;
        } else if (topTable < tableWith1Type + (additionalType * 3)) {
            breakSize = `${origBreakSize - (additionalType * 3)}px`;
        } else if (topTable < tableWith1Type + (additionalType * 4)) {
            breakSize = `${origBreakSize - (additionalType * 4)}px`;
        }
        const styles = document.querySelectorAll('style');
        const pri = document.getElementById("iframetoprint").contentWindow;

        pri.document.open();
        pri.document.write(`<style> td, th{width: 40px; padding-right: 10px !important;} #breakAfter{margin-bottom: ${breakSize}} th, td {white-space: nowrap;  page-break-inside:avoid; page-break-after:auto } thead { display:table-header-group } </style>`);
        styles.forEach(style => {
            if (style.outerHTML.includes('Button') || style.outerHTML.includes('Grid') || style.outerHTML.includes('Typography') || style.outerHTML.includes('Table')) {
                pri.document.write(style.outerHTML);
            }
        });
        pri.document.write(content.outerHTML);
        pri.document.close();
        pri.print();
        iframe.remove();
    }

    render() {
        return (
            <React.Fragment>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.handleClickOpen}
                >
                    Report
                </Button>
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-describedby="alert-dialog-description"
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    id="printDialog"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Button onClick={this.handleClose} color="primary">
                            OK
                        </Button>
                        <Button onClick={this.handlePrint} color="primary">
                            Print
                        </Button>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justify="center">
                            <Grid id="breakAfter" item >
                                <ChaburahPaymentInfoTable
                                    chaburah={this.props.chaburah}
                                    matzahTypes={this.props.matzahTypes}
                                    chaburah_id={this.props.chaburah_id}
                                    order_id={this.props.order_id}
                                    prices={this.props.prices}
                                    payments={this.props.payments}
                                    totalOwedOnItems={this.props.totalOwedOnItems}
                                    totalOwed={this.props.totalOwed}
                                    chaburahPaymentInfoTableInfo={
                                        this.props.chaburahPaymentInfoTableInfo
                                    }
                                    totals={this.props.totals}
                                />
                            </Grid>
                            <Grid id="breakBefore" item>
                                {" "}
                                <PaymentsTable2
                                    matzahTypes={this.props.matzahTypes}
                                    chaburah_id={this.props.chaburah_id}
                                    order_id={this.props.order_id}
                                    payments={this.props.payments}
                                    prices={this.props.prices}
                                    chaburah={this.props.chaburah}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}
