import React, { Component } from "react";
import axios from "axios";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    withStyles,
    TableFooter
} from "@material-ui/core";

const styles = theme => ({
    runLink: {
        textDecoration: "none"
    },
    header: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)'
    },
    footer: {
        color: '#f00',
    }
});

export default withStyles(styles)(
    class ChaburahRunsList extends Component {
        state = {
            matzahTypes: [],
            runs: []
        };

        async componentDidMount() {
            const [{ data: matzahTypes }] = await Promise.all([
                axios.get("/api/matzah_types")
            ]);
            this.setState({ matzahTypes });
        }

        async componentDidUpdate(prevProps) {
            if (prevProps.chaburah !== this.props.chaburah) {
                let mapped = []; //map to export it
                
                if (this.props.chaburah) {
                    const [{ data: orders }] = await Promise.all([
                        axios.get(`/api/chaburos/${this.props.chaburah}/orders`)
                    ]);
                    const [{ data: runs }] = await Promise.all([
                        axios.get(`/api/chaburos/${this.props.chaburah}/orders/${orders[0].id}/runs`)
                    ]);

                    let grandTotals = ['Grand Total'];
                    let allRunsTotalAllTypes = 0;
                    
                    runs.forEach((r, i) => {
                        r.total = r.run_items.reduce((pre, cur) => pre + cur.amount, 0);
                        allRunsTotalAllTypes += r.total;

                       
                        let arr = [];
                        r.run_items.forEach(i=>arr.push(i.amount));
                        mapped.push([r.id, ...arr, r.total])
                    });
                    
                    this.state.matzahTypes.forEach((t, i)=> {
                        let allRunsTotal = 0;
                        runs.forEach((r) => {
                            allRunsTotal += r.run_items[i].amount;
                        });
                        grandTotals.push(allRunsTotal);
                    });
                    grandTotals.push(allRunsTotalAllTypes);

                    mapped.push(grandTotals);
                    this.setState({ runs, grandTotals });                    
                }
                let fields = ['Run Id', 'Reg', 'WW', 'Rosh', 'Rosh WW', 'Broken', 'Totals'];
                this.props.setExportableData(fields, this.props.chaburah, mapped, 'runs');
            }
        }

        render() {
            const { classes } = this.props;
            const { runs, matzahTypes, grandTotals } = this.state;
            let itemsMap = '';
            if (runs.length) {
                itemsMap = runs.map((r) => {
                    return (
                        <TableBody key={r.id}>
                            <TableRow>
                                <TableCell className="td-print">{r.id}</TableCell>
                                {r.run_items.map(type => (
                                    <TableCell key={type.matzah_type_id} className="td-print">{type.amount}</TableCell>
                                ))}
                                <TableCell className="td-print">{r.total}</TableCell>
                            </TableRow>
                        </TableBody>
                    )
                });
                itemsMap.push(
                    <TableFooter key='foot'>
                        <TableRow className={classes.header}>
                            {grandTotals.map((mt,i) => (
                                <TableCell className={`td-print ${classes.footer}`} key={i}>{mt}</TableCell>
                            ))}
                        </TableRow>
                    </TableFooter>
                )
            } else {
                itemsMap =
                    <TableBody><TableRow >
                        <TableCell className="td-print">No runs available</TableCell>
                    </TableRow></TableBody>
            }
            return (
                <Card className={`${classes.card} print-page`}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.header}>
                                <TableCell className="td-print">Run Id</TableCell>
                                {matzahTypes.map(mt => (
                                    <TableCell className="td-print" key={mt.id}>{mt.name}</TableCell>
                                ))}
                                <TableCell className="td-print">Total Lbs</TableCell>
                            </TableRow>
                        </TableHead>
                        {itemsMap}
                    </Table>
                </Card>
            );
        }
    }
);
