import React from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import axios from "axios";

const styles = (theme) => ({
  button: {
    margin: "15px",
  },
});

export default withStyles(styles)(
  class PaymentFormPopUp extends React.Component {
    defaultState = {
      total: "",
      discount: "",
      ...this.props.matzahTypes.reduce((acc, mT) => {
        return { ...acc, [mT.name]: "" };
      }, {}),
      last_name: "",
      open: false,
      scroll: "paper",
    };
    state = this.defaultState;

    handleClickOpen = async () => {
      this.setState(this.defaultState);
      this.setState({ open: true });
    };

    handleClose = () => {
      this.setState({ open: false });
    };
    setTotal = () => {
      var total = this.props.matzahTypes.reduce((total, mt, i) => {
        return (total +=
          this.props.prices[i].price * (this.state[mt.name] || 0));
      }, 0);
      total -= this.state.discount || 0;
      this.setState({
        total: total,
      });
    };
    setTotalAfterDiscount = (discount) => {
      var total = this.props.matzahTypes.reduce((total, mt, i) => {
        return (total +=
          this.props.prices[i].price * (this.state[mt.name] || 0));
      }, 0);
      this.setState({
        total: total - discount,
      });
    };
    handleTypeChange = (name) => (event) => {
      this.setState({ [name]: event.target.value });
    };
    handleChange = (event) => {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.setTotal();
        }
      );
    };
    handleDiscountChange = (event) => {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.setTotalAfterDiscount(value);
        }
      );
    };

    onSubmit = async (event) => {
      if (this.state.last_name === "") {
        return;
      }
      const { chaburah, order_id } = this.props;
      event.preventDefault();
      const payment_items = this.props.matzahTypes.map((matzahType, index) => ({
        amount: this.state[matzahType.name] || 0,
        matzah_type_id: matzahType.id,
      }));
      const data = {
        last_name: this.state.last_name,
        discount: this.state.discount || 0,
        amount: this.state.total || 0,
        payment_items: payment_items,
      };
      try {
        await axios.post(
          `/api/chaburos/${chaburah.id}/orders/${order_id}/payments/`,

          data
        );
        const { data: payments } = await axios.get(
          `/api/chaburos/${chaburah.id}/orders/${order_id}/payments/`
        );
        this.props.setPayments(payments);
      } catch (error) {
        alert("something went wrong", error);
      }
      this.setState({ open: false });
    };

    render() {
      const { matzahTypes } = this.props;
      return (
        <React.Fragment>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleClickOpen}
          >
            Pay
          </Button>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-describedby="alert-dialog-description"
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              Payment Info For {this.props.chaburah.nickname}
            </DialogTitle>
            <DialogContent style={{ maxHeight: 500, overflow: "auto" }}>
              <form onSubmit={this.onSubmit}>
                <Typography
                  variant="title"
                  gutterBottom
                  color="textSecondary"
                />
                <TextField
                  name="last_name"
                  label="Last Name"
                  margin="normal"
                  placeholder="Last Name"
                  type="text"
                  value={this.state.last_name}
                  onChange={this.handleChange}
                  fullWidth
                  required
                />
                {matzahTypes.map((mt, index) => {
                  return this.props.prices[index].price === 0 ? null : (
                    <TextField
                      key={mt.id}
                      name={mt.name}
                      value={this.state[index + 1]}
                      label={mt.name + " $" + this.props.prices[index].price}
                      margin="normal"
                      placeholder="Amount"
                      type="number"
                      onChange={this.handleChange}
                      fullWidth
                    />
                  );
                })}
                
                <TextField
                  name="discount"
                  label="Discount"
                  value={this.state.discount}
                  onChange={this.handleDiscountChange}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  name="total"
                  label="Total"
                  value={"$" + this.state.total}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  name="ccTotal"
                  label="CC Total + 3%"
                  value={"$" + (this.state.total + (this.state.total * .03))}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={this.onSubmit}
                >
                  Submit
                </Button>
              </form>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      );
    }
  }
);
