import React from "react";
import { Form, Field } from "react-final-form";
import {
  withStyles,
  Card,
  Typography,
  TextField,
  Button
} from "@material-ui/core";
import axios from "axios";

// this function is needed b/c I don't know how to use react-final-form-arrays yet
// cooerce the amount to zero if it's not defined so that we get a run item that can be updated later
const mapToRunItems = (runItems, matzahTypes) =>
  matzahTypes.map((mt, i) => ({
    amount: Number(runItems[i] || 0),
    matzah_type_id: mt.id
  }));

const styles = theme => ({
  button: {
    margin: '15px'
  }
})

export default withStyles(styles)(class RunForm extends React.Component {
  onSubmit = async ({ runItems: formData }) => {
    const { chaburah, order_id, matzahTypes, onSuccessfulSubmit } = this.props;
    const runItems = mapToRunItems(formData, matzahTypes);
    await axios.post(
      `/api/chaburos/${chaburah.id}/orders/${order_id}/runs`,
      { run_items: runItems }
    );
    onSuccessfulSubmit();
  };
  validate = () => {};
  render() {
    const { matzahTypes, chaburah, currentRunNum, classes } = this.props;
    return (
      <Card style={{ padding: "40px" }}>
        <Typography variant="title" gutterBottom color="textSecondary">
          {chaburah.nickname}
        </Typography>
        <Form
          onSubmit={this.onSubmit}
          validate={this.validate}
          render={({ handleSubmit, pristine, invalid, form }) => (
            <form
              onSubmit={(...args) => {
                handleSubmit(...args);
                form.reset();
              }}
            >
              <h2>Table #{currentRunNum || 1}</h2>
              {matzahTypes.map((mt, index) => (
                <div key={mt.id}>
                  <Field
                    name={`runItems[${index}]`}
                    render={({ input, meta }) => (
                      <TextField
                        {...input}
                        type="number"
                        autoComplete="off"
                        label={mt.name}
                        placeholder="Amount"
                      />
                    )}
                  />
                </div>
              ))}
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={pristine || invalid}
                className={classes.button}
              >
                Submit
              </Button>
            </form>
          )}
        />
      </Card>
    );
  }
});
