import React, { Component } from "react";
import { Menu, MenuItem, Button } from "@material-ui/core";
import axios from "axios";

export default class YearPicker extends Component {
  state = {
    anchorEl: null,
    years: [],
    currentYear: new Date().getFullYear()
  };

  async componentWillMount() {
    const { data: years } = await axios.get("/api/chaburosyears/");
    const sortedyears = years.map(year => year.year).sort();
    this.setState({ years: sortedyears });
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    this.setState({
      anchorEl: null,
      currentYear: event.currentTarget.id || new Date().getFullYear()
    });
    this.props.setYear(event.currentTarget.id);
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <React.Fragment>
        <Button
          id="1"
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          variant="contained"
          color="primary"
        >
          {this.state.currentYear}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.state.years.map(year => {
            return (
              <MenuItem key={year} id={year} onClick={this.handleClose}>
                {year}
              </MenuItem>
            );
          })}
        </Menu>
      </React.Fragment>
    );
  }
}
