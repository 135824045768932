import React, { Component } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  withStyles
} from "@material-ui/core";

const styles = theme => ({
  runLink: {
    textDecoration: "none"
  },
  header:{
    backgroundColor:'rgba(0, 0, 0, 0.09)'
 }
});

export default withStyles(styles)(
  class ChaburahReportsTable extends Component {
    state = {
      currentYear: new Date().getFullYear()
    };

    render() {
      const { chaburos, classes } = this.props;
      return (
        <Card className={`${classes.card} print-page`}>
          <Table>
            <TableHead>
              <TableRow className={classes.header}>
                <TableCell>Chaburah name</TableCell>
                <TableCell className="td-print">Total Lbs</TableCell>
                <TableCell className="td-print">Price Per Lb (base)</TableCell>              
                <TableCell className="td-print">Total Price</TableCell>
                <TableCell className="td-print">Tables</TableCell>
                <TableCell className="td-print">Average Lbs Per Table</TableCell>
                <TableCell className="td-print">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chaburos
                .map((chaburah, i) => (
                  <React.Fragment key={i}>
                      <TableRow>
                          {chaburah.map((td, i) => (
                                <TableCell key={i} className="td-print">{td}</TableCell>  
                          ))}
                      </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </Card>
      );
    }
  }
);
