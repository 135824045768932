import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

export default function ProcessPayment() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const dataParam = queryParams.get('data');

	// Parse the JSON string into an object
	const data = dataParam ? JSON.parse(dataParam) : {};

	useEffect(() => {
		localStorage.setItem('paymentResult', data.status?.toUpperCase());
		const paymentInfo = JSON.parse(localStorage.getItem('payment_items'));
		localStorage.removeItem('payment_items');
		if (data.status?.toUpperCase() === 'OK') {
			axios
				.post(
					`/api/chaburos/${paymentInfo.chaburaId}/orders/${paymentInfo.orderId}/payments/`,
					paymentInfo,
				)
				.then((response) => {
					window.close();
				});
		} else {
			window.close();
		}
	}, [data]);

	return (
		<div style={{ textAlign: 'center' }}>
			<h1>
				{data.status?.toUpperCase() === 'OK'
					? 'Payment processed Successfully'
					: 'Payment Errored please try again or select a new Payment Method'}
			</h1>
			<div style={{ margin: '0 auto', width: '50%' }}>
				<h1
					style={{
						color: 'red',
						border: '1px solid black',
						padding: 25,
						fontSize: 50,
					}}
				>
					Please close tab and go back
				</h1>
			</div>
		</div>
	);
}
