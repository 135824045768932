import React, { Component } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
export default class UpdateRunInfo extends Component {
  state = {
    open: false
  };
  componentDidMount() {
    this.setRun();
  }
  componentDidUpdate(prevProps) {
    if (this.props.run !== prevProps.run) {
      this.setRun();
    }
  }
  setRun = () => {
    const run = this.props.run;
    const sortedRunItems = run.run_items.slice().sort(function(a, b) {
      return a.matzah_type_id - b.matzah_type_id;
    });
    this.setState({ run, sortedRunItems });
  };
  handleClickOpen = () => {
    this.props.matzahTypes.map((matzahType, index) =>
      this.setState({
        [matzahType.name]: this.state.sortedRunItems[index].amount
      })
    );
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = async event => {
    this.setState({ open: false });
    const run = this.state.run;
    await Promise.all(
      this.state.sortedRunItems.map((li, index) => {
        const id = li.id;
        const amount = this.state[this.props.matzahTypes[index].name] || 0;
        const orderId = run.order_id;
        const chaburahId = run.chaburah_id;
        const run_id = run.id;
        return axios.put(
          `/api/chaburos/${chaburahId}/orders/${orderId}/runs/${run_id}/run_items/${id}`,
          { amount }
        );
      })
    );
    this.props.setUpdatedRun();
  };

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };
  render() {
    return (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen}
        >
          Run # {this.props.runNumber}
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle id="form-dialog-title">
            Update Run # {this.props.runNumber}
          </DialogTitle>
          <DialogContent>
            {this.props.matzahTypes.map(matzahType => (
              <TextField
                key={matzahType.id}
                autoFocus
                name={matzahType.name}
                margin="dense"
                label={matzahType.name}
                type="number"
                value={this.state[matzahType.name]}
                onChange={this.handleChange}
                fullWidth
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
