import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Dropdown from "./Dropdown";

const styles = theme => ({});

export default withStyles(styles)(
  class ChaburahSearch extends Component {
    render() {
      const { chaburos, selectChaburah } = this.props;
      return (
        <div>
          <div>
            <Dropdown
              values={chaburos}
              placeholder="Find Past Chaburah"
              handleChange={selectChaburah}
            />
          </div>
        </div>
      );
    }
  }
);
