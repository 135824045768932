import React, { Component } from 'react';
import { withStyles, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import YearContext from '../components/YearContext';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    justifyContent: 'center',
    margin: '0 100px',
  },
  link: {
    margin: '20px 10px',
    color: '#3f51b5',
    textDecoration: 'none',
    textWeight: 'bold',
  },
});

export default withRouter(
  withStyles(styles)(
    class ReportsPage extends Component {
      state = {
        reports: [
          // { bnt: 'Chaburah Runs', url: 'page/Chabura-Runs' },
          //{ bnt: 'Chaburah Payments', url: 'page/Chabura-Payments' },
          //{ bnt: 'Year End', url: 'Chaburah-Reports' },
          //{ bnt: 'Chaburah Payments Summary ', url: 'summary/Chaburah-Payments-Summary' },
          //{ bnt: 'Chaburah Runs Summary', url: 'summary/Chaburah-Runs-Summary' },
          { bnt: 'Totals', url: 'summary/totals-Summary' },
          { bnt: 'Daily Totals', url: 'daily-totals' },
        ],
      };

      static contextType = YearContext;

      render() {
        const { classes, history, match } = this.props;
        return (
          <div className={classes.root}>
            {this.state.reports.map((report) => {
              return (
                <Button
                  key={report.bnt}
                  variant="outlined"
                  onClick={() => {
                    history.push(`${match.path}/${report.url}`);
                  }}
                  className={classes.link}
                >
                  {report.bnt}
                </Button>
              );
            })}
          </div>
        );
      }
    }
  )
);
