import React, { Component } from 'react';


const style = {
    minHeight: "100vh",
    margin: "50px",
    color: "grey"
};


export default class HomePage extends Component {
  render() {
    return <h2 style={style} >Please choose Active or Complete above</h2> 
  }
}
