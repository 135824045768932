import React, { Component } from "react";
import {
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  withStyles
} from "@material-ui/core";
const styles = theme => ({
  card: {
    padding: "10px"
    // maxHeight: "200px",
    // overflow: "auto"
  },
  headerRow: {
    textAlign: "center",
    color: "red"
  }
});
export default withStyles(styles)(
  class PaymentsTable2 extends Component {
    state = {};
    render() {
      const { payments, matzahTypes, classes } = this.props;
      return (
        <Card className={classes.card}>
          <Typography
            align="center"
            variant="title"
            color="textSecondary"
            className={classes.header}
          >
            Payers Info
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={`${classes.headerRow} td-print`}>Last Name</TableCell>
                {matzahTypes.map((matzahType, i) => (
                  <TableCell
                    className={`${classes.headerRow} td-print`}
                    key={"matzahType" + matzahType.id}
                  >
                    {matzahType.name}
                  </TableCell>
                ))}
                <TableCell className={`${classes.headerRow} td-print`}>Paid</TableCell>
                <TableCell className={`${classes.headerRow} td-print`}>Discount</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map(payment => (
                <TableRow key={payment.id}>
                  <TableCell className={"td-print"}>{payment.last_name}</TableCell>
                  {payment.payment_items
                    .sort(function(a, b) {
                      return a.matzah_type_id - b.matzah_type_id;
                    })
                    .map((payment_item, i) => (
                      <TableCell className={"td-print"} key={"payment_item" + i}>
                        {payment_item.amount + "lbs"}
                      </TableCell>
                    ))}
                  <TableCell className={"td-print"}>{"$" + payment.payment_amount}</TableCell>
                  <TableCell className={"td-print"}>{"$" + payment.discount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <Typography
            align="center"
            variant="title"
            color="textSecondary"
            className={classes.header}
          >
            Payers Info
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.headerRow}>First Name</TableCell>
                <TableCell className={classes.headerRow}>Last Name</TableCell>
                <TableCell className={classes.headerRow}>Paid</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Table>
            <TableBody>
              {payments
                .sort(function(a, b) {
                  return b.id - a.id;
                })
                .map(payment => (
                  <TableRow key={payment.id}>
                    <TableCell>{payment.first_name}</TableCell>
                    <TableCell>{payment.last_name}</TableCell>
                    <TableCell>{payment.payment_amount}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table> */}
        </Card>
      );
    }
  }
);
