import React, { Component } from "react";
import axios from "axios";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    withStyles,
    TableFooter
} from "@material-ui/core";

const styles = theme => ({
    runLink: {
        textDecoration: "none"
    },
    header: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)'
    },
    footer: {
        color: '#f00',
    }
});

export default withStyles(styles)(
    class ChaburahPaymentsPage extends Component {
        state = {
            matzahTypes: [],
            payments: []
        };

        async componentDidMount() {
            const [{ data: matzahTypes }] = await Promise.all([
                axios.get("/api/matzah_types")
            ]);
            this.setState({ matzahTypes });
        }

        async componentDidUpdate(prevProps) {
            if (prevProps.chaburah !== this.props.chaburah) {
                let mapped = []; //map to export it
                
                if (this.props.chaburah) {
                    const [{ data: orders }] = await Promise.all([
                        axios.get(`/api/chaburos/${this.props.chaburah}/orders`)
                    ]);
                    const [{ data: payments }] = await Promise.all([
                        axios.get(`/api/chaburos/${this.props.chaburah}/orders/${orders[0].id}/payments`)
                    ]);
                    let totalPaid = payments.reduce((a,b)=>a + b.payment_amount, 0);
                    this.setState({ payments, totalPaid });
                    
                    payments.forEach(p=>{
                        let types = [];
                        p.payment_items.forEach(pi => {
                            types.push([pi.amount])
                        });
                        mapped.push([p.first_name, p.last_name, ...types, p.payment_amount])
                    });
                    mapped.push(['TOTAL PAID','','','','','','', totalPaid])
                }
                
                let fields = ['First Name', 'Last Name', 'Reg', 'WW', 'Rosh', 'Rosh WW', 'Broken', 'Total Paid'];
                this.props.setExportableData(fields, this.props.chaburah, mapped, 'payments');
            }
        }

        render() {
            const { classes } = this.props;
            const { payments, matzahTypes, totalPaid } = this.state;
            let itemsMap = '';
            if (payments.length) {
                itemsMap = payments.map((r) => {
                    return (
                        <TableBody key={r.id}>
                            <TableRow>
                                <TableCell >{r.first_name} {r.last_name}</TableCell>
                                {r.payment_items.map(type => (
                                    <TableCell key={type.matzah_type_id} className="td-print">{type.amount}</TableCell>
                                ))}
                                <TableCell className="td-print">{r.payment_amount}</TableCell>
                            </TableRow>
                        </TableBody>
                    )
                });
                itemsMap.push(
                    <TableFooter key='foot'>
                        <TableRow className={classes.header}>
                            <TableCell colSpan={7} className={classes.footer}>Total Lbs Paid For: {totalPaid}</TableCell>
                        </TableRow>
                    </TableFooter>
                )
            } else {
                itemsMap =
                    <TableBody><TableRow >
                        <TableCell className="td-print">No payments available</TableCell>
                    </TableRow></TableBody>
            }
            return (
                <Card className={`${classes.card} print-page`}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.header}>
                                <TableCell className="td-print">Name</TableCell>   
                                {matzahTypes.map(mt => (
                                    <TableCell className="td-print" key={mt.id}>{mt.name}</TableCell>
                                ))}
                                <TableCell className="td-print">Total Paid</TableCell>
                            </TableRow>
                        </TableHead>
                        {itemsMap}
                    </Table>
                </Card>
            );
        }
    }
);
