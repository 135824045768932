import React, { Component } from 'react';
import axios from 'axios';
import YearContext from '../YearContext';
import DownloadPrint from '../DownloadPrint';
import { withStyles, CircularProgress, Grid } from '@material-ui/core';
import SummariesTableComponent from './summaries/SummariesTableComponent';

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		margin: '0 auto',
		textAlign: 'center',
	},
	input: {
		width: '100%',
		margin: '20px',
	},
	table: {
		width: '85%',
		margin: '0 auto',
	},
	progress: {
		margin: theme.spacing.unit * 2,
	},
});

export default withStyles(styles)(
	class ChaburahTotals extends Component {
		state = {
			// filterdChaburos: [],
			headers: [],
			rows: [],
			currentYear: new Date().getFullYear(),
			export: {
				filename: '',
				data: {
					fields: [],
					body: [],
				},
			},
		};

		async componentDidMount() {
			this.setState({ exportReady: false });
			await this.getData();
			this.setState({ exportReady: true });
		}

		async componentDidUpdate(prevProps) {
			if (this.props.currentYear !== prevProps.currentYear) {
				this.setState({ exportReady: false });
				await this.getData();
				this.setState({ exportReady: true });
			}
		}

		setExportableData = (fields, mapped, report) => {
			this.setState({ export: { filename: `matzah-types.csv`, data: { body: mapped, fields } } });
			this.setState({ exportReady: true });
		};

		static contextType = YearContext;

		async getData() {
			if (!this.props.currentYear) {
				return;
			}
			this.setState({ headers: ['Date', 'Total Regular', 'Total Rosh'] });

			const year = this.props.currentYear;
			try {
				const { data } = await axios.get(
					`/api/reports/getMatzahTypeTotals?year=${this.props.currentYear}`,
				);
				const mappedRows = data.map((d) => {
					const theDate = new Date(d.date)
					const date = `${theDate.getMonth() + 1}/${theDate.getDate()}/${theDate.getFullYear()}`;
					return Object.values({ ...d, date });
				});
				this.setState({ currentYear: year, rows: mappedRows });
				this.setExportableData(this.state.headers, mappedRows, `Totals-${year}`);
			} catch (err) {
				console.log(err);
			}
		}

		/* chaburahSearch = (e) => {
            let filtered = filterMenu(this.state.mapped, e.target.value);
            this.setState({ filterdChaburos: filtered });
        }
        */

		render() {
			let download;
			let loader;
			if (this.state.exportReady) {
				download = <DownloadPrint diasabled='false' import={this.state.export} />;
				loader = <></>;
			} else {
				download = <DownloadPrint disabled='true' import={this.state.export} />;
				loader = <CircularProgress className={this.props.classes.progress} size={50} thickness={7} />;
			}
			return (
				<div justify='center' className={this.props.classes.root}>
					<br />
					<Grid container justify='space-around' alignItems='center'>
						<Grid item className='noPrint'>
							{download}
						</Grid>
					</Grid>
					<br />
					<div className={this.props.classes.table}>
						<SummariesTableComponent headers={this.state.headers} fields={this.state.rows} />
					</div>
				</div>
			);
		}
	},
);
