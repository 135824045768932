import React, { Component } from "react";

import {
  withStyles,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@material-ui/core";
import { getTotals } from "../helpers/getTotals";
const CustomCell = withStyles(theme => ({
  root: {
    paddingRight: "24px"
  }
}))(TableCell);
export default class TotalsPopUp extends Component {
  state = {
    chaburah: [],
    lineItems: [],
    runs: [],
    totals: [],
    sortedLineItems: [],
    matzahTypes: [],
    totalRemaining: [],
    open: false,
    scroll: "paper"
  };

  handleClickOpen = async () => {
    const results = await getTotals(
      this.props.chaburah_id,
      this.props.order_id
    );
    this.setState(() => results);
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen}
        >
          total
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-describedby="alert-dialog-description"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            {"Totals for " + this.props.chaburah_name + " "}
            <Button onClick={this.handleClose} color="primary">
              OK
            </Button>
            <Button onClick={window.print} color="primary">
              Print
            </Button>
            <Table>
              <TableBody>
                <TableRow>
                  <CustomCell />
                  {this.state.matzahTypes.map(mt => (
                    <CustomCell key={mt.id}>{mt.name}</CustomCell>
                  ))}
                </TableRow>
                <TableRow>
                  <CustomCell>Ordered</CustomCell>

                  {this.state.sortedLineItems.map(li => (
                    <CustomCell key={li.id}>{li.amount}</CustomCell>
                  ))}
                </TableRow>
                <TableRow>
                  <CustomCell>Packed</CustomCell>
                  {this.state.totals.map(t => (
                    <CustomCell key={t.id}>{t.amount}</CustomCell>
                  ))}
                </TableRow>
                <TableRow>
                  <CustomCell>Remaining</CustomCell>
                  {this.state.totalRemaining.map(t => (
                    <CustomCell key={t.id}>{t.amount}</CustomCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </DialogTitle>
          <DialogContent style={{ maxHeight: 300, overflow: "auto" }}>
            <Table>
              <TableBody>
                {this.state.runs
                  .slice()
                  .reverse()
                  .map((run, index) => (
                    <TableRow key={`run-${run.id}`}>
                      <CustomCell key={run.id}>
                        Run # {Math.abs(index - this.state.runs.length)}
                      </CustomCell>
                      {run.run_items
                        .slice()
                        .sort(function(a, b) {
        return a.matzah_type_id - b.matzah_type_id;
      })
                        .map(ri => (
                          <CustomCell key={ri.id}>{ri.amount}</CustomCell>
                        ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
