import React, { Component } from "react";
import { withStyles, TextField } from "@material-ui/core";

const styles = theme => ({
  textField: {
    marginLeft: "10px",
    marginRight: "10px"
  }
});
export default withStyles(styles)(
  class DateChooser extends Component {
    render() {
      const { classes } = this.props;
      return (
        <form noValidate>
          <TextField
            className={classes.textField}
            id="date"
            type="date"
            onChange={this.props.selectedDate}
            InputLabelProps={{
              shrink: true
            }}
          />
        </form>
      );
    }
  }
);
