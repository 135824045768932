import React, { Component } from "react";
import { Grid, withStyles } from "@material-ui/core";
import axios from "axios";
import ChaburahFilter from "../components/ChaburahFilter";
import ChaburahTable from "../components/ChaburahTable";
import YearContext from "../components/YearContext";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
  },
});

export default withStyles(styles)(
  class ChaburosPage extends Component {
    state = {
      chaburos: [],
      filterdChaburos: [],
    };
    async componentWillMount() {
      this.getCaburos();
    }
    async componentDidUpdate(prevProps) {
      if (this.props.currentYear !== prevProps.currentYear) {
        this.getCaburos();
      }
    }
    async getCaburos() {
      const year = this.props.currentYear;
      const { data: chaburos } = await axios.get(
        "/api/chaburos?include=orders&year=" +
          year +
          "&where=orders.status&is=COMPLETED"
      );
      this.setState({ chaburos });
      this.setState({ filterdChaburos: chaburos });
    }
    stateSetter = (filterdChaburos) => {
      this.setState({ filterdChaburos });
    };
    static contextType = YearContext;
    render() {
      return (
        <div>
          <Grid
            container
            justify="center"
            className={this.props.classes.root}
            spacing={16}
          >
            <Grid item xs={2}>
              <ChaburahFilter
                chaburos={this.state.chaburos}
                stateSetter={this.stateSetter}
              />
            </Grid>
            <Grid item xs={9}>
              <ChaburahTable
                chaburos={this.state.filterdChaburos}
                user={this.props.user}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
  }
);
