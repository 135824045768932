import React from "react";

import {
  withStyles,
  Typography,
  TextField,
  Button,
  Card,
} from "@material-ui/core";
import axios from "axios";
import { getTotals } from "../helpers/getTotals";

const styles = (theme) => ({
  card: {
    padding: "40px",
  },
  button: {
    margin: "15px",
  },
});

export default withStyles(styles)(
  class PriceForm extends React.Component {
    state = {
      totals: [],
      lineItems: [],
      mt: {
        ...this.props.matzahTypes.reduce((acc, mT) => {
          return { ...acc, [mT.name]: "" };
        }, {}),
      },
    };
    async componentDidMount() {
      const results = await getTotals(
        this.props.chaburah.id,
        this.props.order_id
      );
      this.setState(() => results);
    }

    handleChange = (event) => {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState((prevState) => ({
        mt: {
          ...prevState.mt,
          [name]: value,
        },
      }));
    };

    canBeSubmitted = () => {
      return !Object.values(this.state.mt)
        .map((mt) => (mt ? true : false))
        .includes(true);
    };
    onSubmit = async (event) => {
      const { chaburah, order_id, matzahTypes } = this.props;
      event.preventDefault();
      const prices = matzahTypes.map((mt, i) => ({
        price: this.state.mt[mt.name.replace(/\s+/g, "")] || 0,
        matzah_type_id: mt.id,
      }));
      try {
        await Promise.all(
          prices.map((price) =>
            axios.post(
              `/api/chaburos/${chaburah.id}/orders/${order_id}/prices`,
              price
            )
          )
        );
        const { data: newPrices } = await axios.get(
          `/api/chaburos/${chaburah.id}/orders/${order_id}/prices`
        );
        this.props.setPrices(newPrices);
      } catch (error) {
        console.log(error);
        alert("something went wrong", error);
      }
    };

    render() {
      const { matzahTypes, classes } = this.props;
      return !this.state.totals.length ? null : (
        <Card className={classes.card}>
          <form onSubmit={this.onSubmit}>
            <Typography
              align="center"
              variant="display1"
              gutterBottom
              color="textSecondary"
              className={classes.header}
            >
              Set Prices For {' '}
              {this.props.chaburah.name + " " + this.props.chaburah.nickname}
            </Typography>
            {matzahTypes.map((mt, index) => {
              const totalItem = this.state.totals.find((t) => t.matzah_type_id === mt.id)
              const lineItem = this.state.lineItems.find((t) => t.matzah_type_id === mt.id)
              return (totalItem && totalItem.amount !== 0) ||
                (lineItem && lineItem.amount !== 0) ? (
                <TextField
                  key={mt.id}
                  autoFocus
                  name={mt.name.replace(/\s+/g, "")}
                  value={this.state.mt[mt.name.replace(/\s+/g, "")]}
                  label={mt.name}
                  margin="normal"
                  placeholder="Amount"
                  type="number"
                  onChange={this.handleChange}
                  fullWidth
                />
              ) : null;
            })}

            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={this.onSubmit}
              disabled={this.canBeSubmitted()}
            >
              Submit
            </Button>
          </form>
        </Card>
      );
    }
  }
);
