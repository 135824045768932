import React from "react";
import { Form, Field } from "react-final-form";
import {
  withStyles,
  Card,
  Typography,
  TextField,
  Button
} from "@material-ui/core";
import axios from "axios";

const styles = theme => ({
  button: {
    margin: '20px',
  }
});

export default withStyles(styles)(
  class LoginForm extends React.Component {
    onSubmit = async (formData) => {
      try {
        const { data: user } = await axios.post('/api/login', formData);
        this.props.onSubmit(user);
      } catch (error) {
        this.props.onSubmit(false);
        alert("username or password is incorrect");
      }
    };
    validate = () => {};
    render() {
      const { classes } = this.props;
      return (
        <Card style={{ padding: "40px" }}>
          <Typography variant="display1" gutterBottom color="textSecondary">
            Login
          </Typography>
          <Form
            onSubmit={this.onSubmit}
            validate={this.validate}
            render={({ handleSubmit, pristine, invalid, form }) => (
              <form
                onSubmit={(...args) => {
                  handleSubmit(...args);
                  form.reset();
                }}
              >
              <div>
                <Field
                  name="username"
                  render={({ input, meta }) => (
                    <TextField
                      {...input}
                      autoComplete="off"
                      label="Name"
                      placeholder="Name"
                    />
                  )}
                />
              </div>
              <div>
                <Field
                  name="password"
                  render={({ input, meta }) => (
                    <TextField
                      {...input}
                      type="password"
                      autoComplete="off"
                      label="Password"
                      placeholder="Password"
                    />
                  )}
                />
              </div>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={pristine || invalid}
                  className={classes.button}
                >
                  Log In
                </Button>
              </form>
            )}
          />
        </Card>
      );
    }
  }
);
