import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { Grid, withStyles } from "@material-ui/core";

import LoginForm from "../components/LoginForm";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh'
  }
})

export default withStyles(styles)(class LoginPage extends Component {
  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    if (this.props.redirectToReferrer) {
      return <Redirect to={from} />;
    }
    return (
      <Grid container justify="center" alignItems="center" alignContent="center" className={this.props.classes.root} >
        <Grid item xs={7} sm={4} md={3} lg={2} xl={2}  >
          <LoginForm onSubmit={this.props.onSubmit} />
        </Grid>
      </Grid>
    )
  }
});
