import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import axios from "axios";
import "typeface-roboto";

import NavBar from "./components/NavBar";
import PrivateRoute from "./components/PrivateRoute";
import HomePage from "./pages/HomePage";
import ChaburosPage from "./pages/ChaburosPage";
import OrderPage from "./pages/OrderPage";
import RunPage from "./pages/RunPage";
import LoginPage from "./pages/LoginPage";
import ChaburosPaymentsPage from "./pages/ChaburosPaymentsPage";
import PaymentsPage from "./pages/PaymentsPage";
import ReportsPage from "./pages/ReportsPage";
import ChaburahReports from "./components/reports/YearEndReports";
import PageNotFound from "./pages/PageNotFound";
import ChaburahList from "./components/reports/ChaburaListReport";
import ChaburahSum from "./components/reports/summaries/ChaburahSummaries";
import MatzahTypesGrouped from "./components/reports/MatzahTypesGrouped";
import KioskSelfPay from "./pages/KioskSelfPay";
import ProcessPayment from "./pages/ProcessPayment";
// import YearContext from "./components/YearContext";

export default class App extends Component {
    state = {
        user: undefined,
        role: undefined,
        currentYear: new Date().getFullYear(),
        gotUser: false
    };
    async componentWillMount() {
        const { data: user } = await axios.get("/api/users");
        if (user) {
            this.setState({ user: user, role: user.role });
        }
        this.setState({ gotUser: true });
    }
    setYear = year => {
        this.setState(() => ({ currentYear: year || new Date().getFullYear() }));
    };
    render() {
        if (!this.state.gotUser) {
            return <React.Fragment></React.Fragment>;
        }
        return (
            <Router>
                <div>
                    <CssBaseline />
                    {this.state.user && (
                        <NavBar
                            setYear={this.setYear}
                            user={this.state.user}
                            onLogout={() => {
                                this.setState({ user: undefined, role: undefined });
                            }}
                        />
                    )}
                    <Route path="/processPayment" component={ProcessPayment} />
                    <Route
                        path="/login"
                        render={props => (
                            <LoginPage
                                {...props}
                                onSubmit={user => {
                                    this.setState({ user: user, role: user.role });
                                }}
                                redirectToReferrer={!!this.state.user}
                            />
                        )}
                    />
                    <PrivateRoute
                        exact
                        path="/"
                        component={HomePage}
                        isAuthenticated={!!this.state.user}
                        isAuthorised={true}
                    />
                    <PrivateRoute
                        exact
                        path="/orders"
                        component={OrderPage}
                        isAuthenticated={!!this.state.user}
                        isAuthorised={true}
                        user={this.state.user}
                    />

                    <PrivateRoute
                        exact
                        path="/chaburos"
                        component={ChaburosPage}
                        isAuthenticated={!!this.state.user}
                        currentYear={this.state.currentYear}
                        isAuthorised={true}
                        user={this.state.user}
                    />
                    <PrivateRoute
                        path="/chaburos/:chaburah_id/orders/:order_id/runs"
                        component={RunPage}
                        isAuthenticated={!!this.state.user}
                        isAuthorised={true}
                    />
                    <PrivateRoute
                        exact
                        path="/payments"
                        component={ChaburosPaymentsPage}
                        isAuthenticated={!!this.state.user}
                        currentYear={this.state.currentYear}
                        isAuthorised={this.state.role === "admin"}
                    />
                    <PrivateRoute
                        path="/chaburos/:chaburah_id/orders/:order_id/payments"
                        component={PaymentsPage}
                        isAuthenticated={!!this.state.user}
                        isAuthorised={this.state.role === "admin"}
                    />

                    <PrivateRoute
                        exact path="/reports"
                        component={ReportsPage}
                        isAuthenticated={!!this.state.user}
                        currentYear={this.state.currentYear}
                        isAuthorised={this.state.role === "admin"}
                    />
                    <PrivateRoute
                        path="/reports/page/"
                        component={ChaburahList}
                        isAuthenticated={!!this.state.user}
                        currentYear={this.state.currentYear}
                        isAuthorised={this.state.role === "admin"}
                    />
                    <PrivateRoute
                        exact path="/reports/daily-totals"
                        component={MatzahTypesGrouped}
                        isAuthenticated={!!this.state.user}
                        currentYear={this.state.currentYear}
                        isAuthorised={this.state.role === "admin"}
                    />
                    <PrivateRoute
                        path="/reports/Chaburah-Reports"
                        component={ChaburahReports}
                        isAuthenticated={!!this.state.user}
                        currentYear={this.state.currentYear}
                        isAuthorised={this.state.role === "admin"}
                    />
                    <PrivateRoute
                        exact
                        path="/kiosk/"
                        component={KioskSelfPay}
                        isAuthenticated={!!this.state.user}
                        currentYear={this.state.currentYear}
                        isAuthorised={true}
                    />
                    <PrivateRoute
                        path="/reports/summary/"
                        component={ChaburahSum}
                        isAuthenticated={!!this.state.user}
                        currentYear={this.state.currentYear}
                        isAuthorised={this.state.role === "admin"}
                    />
                    <PrivateRoute
                        path="/reports/Year-end"
                        component={PageNotFound}
                        isAuthenticated={!!this.state.user}
                        currentYear={this.state.currentYear}
                        isAuthorised={this.state.role === "admin"}
                    />
                </div>
            </Router>
        );
    }
}
