import React, { Component } from 'react';
import { withStyles, TextField, CircularProgress } from '@material-ui/core';
import ChaburahReportsTable from './ChaburahReportsTable';
import axios from 'axios';
import DownloadPrint from '../DownloadPrint';
import YearContext from "../YearContext";
import filterMenu from '../../helpers/searchChaburah';
import { $format, format } from '../../helpers/numberFormat';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        margin: '20px'
    },
    table: {
        width: '90%',
        margin: '0 auto'
    },
    progress: {
        margin: theme.spacing.unit * 2,
    }
});

export default withStyles(styles)(
    class ChaburahReports extends Component {
        state = {
            filterdChaburos: [],
            matzahTypes: [],
            mapped: [],
            inputFilter: '',
            currentYear: new Date().getFullYear(),
            export: {
                filename: '', data: {
                    body: [], fields: []
                }
            },
            downloadmap: []
        }

        async componentDidMount() {
            this.setState({ exportReady: false });
            await this.getChaburos();
            await this.setExportableData();
            this.setState({ exportReady: true });
        };

        async componentDidUpdate(prevProps) {
            if (this.props.currentYear !== prevProps.currentYear) {
                this.setState({ exportReady: false });
                await this.getChaburos();
                await this.setExportableData();
                this.setState({ exportReady: true });
            }
        }

        static contextType = YearContext;

        async getChaburos() {
            const mapped = [];
            const downloadmap = [];
            const year = this.props.currentYear;
            const [{ data: chaburos }, { data: matzahTypes }] = await Promise.all([
                axios.get(`/api/chaburos/${year}/getAll`),
                axios.get("/api/matzah_types")
            ]);

            const [{ data: prices }] = await Promise.all([
                axios.get(`/api/orders/${year}/prices`),
            ]);

            for (const chaburah in chaburos) {
                const c = chaburos[chaburah];
                const chaburahPrices = prices.filter(p => c.id === p.id);
                const regPrice = chaburahPrices.length > 1 ? chaburahPrices.find(a => a.matzah_type_id === 1).price : 0;

                const totalPrice = chaburahPrices.reduce((pre, curr) => pre + (curr.price * curr.amount), 0);

                let LbPerTable = +c.tables === 0 ? 0 : +c.totallbs / +c.tables;
                mapped.push([c.name, c.totallbs, $format.format(regPrice), $format.format(totalPrice), c.tables, format.format(LbPerTable), c.created_at.substr(5, 5)])

                downloadmap.push([c.name, c.totallbs, `$${regPrice}`, `$${totalPrice}`, c.tables, LbPerTable, c.created_at.substr(5, 5)])
            }
            this.setState({ currentYear: year, chaburos, matzahTypes, filterdChaburos: mapped, mapped, downloadmap })
        }


        setExportableData = async () => {
            let fields = ['Chaburah name', 'Total Lbs', 'PricePerLb (Reg)', 'Total Price', 'Tables', 'AverageLbsPerTable', 'Date'];
            this.setState({ export: { filename: `YearEnd-${this.state.currentYear}.csv`, data: { body: this.state.downloadmap, fields } } });
        };

        chaburahSearch = (e) => {
            let filtered = filterMenu(this.state.mapped, e.target.value);
            this.setState({ filterdChaburos: filtered });
        }


        render() {
            let download;
            let loader;
            if (this.state.exportReady) {
                download = <DownloadPrint diasabled='false' import={this.state.export} />;
                loader = <></>;
            } else {
                download = <DownloadPrint disabled='true' import={this.state.export} />;
                loader = <CircularProgress className={this.props.classes.progress} size={50} thickness={7} />;
            }

            return (
                <div
                    justify="center"
                    className={this.props.classes.root}
                >
                    <br />
                    <div className="noPrint">
                        {download}
                    </div>
                    <div className={`noPrint ${this.props.classes.input}`}>
                        <TextField id="filled-basic" label="Search Chaburah" variant="filled" onChange={e => {
                            this.chaburahSearch(e)}}
                         />
                    </div>
                    <div className={`${this.props.classes.table} parentOf-printPage`}>
                        <ChaburahReportsTable
                            className='print-page'
                            chaburos={this.state.filterdChaburos}
                            matzahTypes={this.state.matzahTypes}
                        />
                        {loader}
                    </div>
                </div>
            )
        }
    }
)

