import React, { Component } from "react";
import {
  List,
  ListItem,
  ListItemText,
  withStyles,
  Button
} from "@material-ui/core";
import ReactToPrint from "react-to-print";
import Print from "./Print";
const styles = theme => ({
  print: {
    width: "50%",
    backgroundColor: "red",
    textAlign: "center",
    margin: "auto",
    height: "50%",
    border: "3px solid green",
    padding: "10px"
  }
});
export default withStyles(styles)(
  class PrintThis extends Component {
    print = () => {
      const { classes } = this.props;
      // matzoh taken and amount paid
      const { amountPaid } = this.props;
      return (
        <div className={classes.print}>
          <List component="nav">
            <ListItem>
              <ListItemText primary="test" />
            </ListItem>
            <ListItem>
              <ListItemText primary={amountPaid} />
            </ListItem>
          </List>
        </div>
      );
    };
    render() {
      const { matzohTaken, amountPaid, matzahTypes } = this.props;
      return (
        <React.Fragment>
          <ReactToPrint
            trigger={() => (
              <Button variant="outlined" color="primary">
                Print
              </Button>
            )}
            content={() => this.componentRef}
          />
          <div style={{ display: "none" }}>
            <Print
              ref={el => (this.componentRef = el)}
              matzohTaken={matzohTaken}
              amountPaid={amountPaid}
              matzahTypes={matzahTypes}
            />
          </div>
        </React.Fragment>
      );
    }
  }
);
