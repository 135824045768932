import React from "react";
import Downshift from "downshift";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot
        },
        ...InputProps
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected =
    ((selectedItem && selectedItem.name) || "").indexOf(suggestion.name) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      {suggestion.name} - {suggestion.nickname}
    </MenuItem>
  );
}

function getSuggestions(values, inputValue) {
  let count = 0;

  return values.filter(value => {
    const keep =
      (!inputValue ||
        value.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
        (value.nickname &&
          value.nickname.toLowerCase().indexOf(inputValue.toLowerCase()) !==
            -1)) &&
      count < 5;

    if (keep) {
      count += 1;
    }

    return keep;
  });
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    flexGrow: 1,
    position: "float"
  },
  paper: {
    zIndex: 1,
    marginTop: theme.spacing.unit,
    height: "220px",
    left: 0,
    right: 0
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  inputRoot: {
    flexWrap: "wrap",
    height: "30px"
  }
});

function Dropdown(props) {
  const { values, placeholder, handleChange, classes } = props;

  return (
    <div className={classes.root}>
      <Downshift
        onChange={handleChange}
        itemToString={item => (item ? `${item.name} - ${item.nickname}` : "")}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex,
          clearSelection
        }) => (
          <div className={classes.container}>
            {renderInput({
              // fullWidth: true,
              classes,
              InputProps: getInputProps({
                placeholder,
                onChange: e => {
                  if (e.target.value === "") {
                    clearSelection();
                  }
                }
              })
            })}
            {isOpen ? (
              <Paper className={classes.paper} square>
                {getSuggestions(values, inputValue).map((suggestion, index) =>
                  renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({ item: suggestion }),
                    highlightedIndex,
                    selectedItem
                  })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    </div>
  );
}

export default withStyles(styles)(Dropdown);
