import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  withStyles,
  Button,
} from "@material-ui/core";
import TotalsPopUp from "./TotalsPopUp";

const styles = (theme) => ({
  runLink: {
    textDecoration: "none",
  },
});

export default withStyles(styles)(
  class ChaburahTable extends Component {
    state = {
      currentYear: new Date().getFullYear(),
    };
    handleEndOrder = async (chaburah_id, order_id) => {
      try {
        await axios.put(`/api/chaburos/${chaburah_id}/orders/${order_id}`, {
          status: "RUNNING",
        });
      } catch (error) {
        alert("an error occured");
        console.log(error);
      }
    };

    render() {
      const { chaburos, classes, user } = this.props;
      return (
        <Card className={classes.card}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rosh chaburah</TableCell>
                <TableCell>Chaburah name</TableCell>
                <TableCell>Completed On</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {chaburos
                .map((chaburah) => {
                  chaburah.orders.sort(function (a, b) {
                    return (
                      new Date(b.updated_at.substr(0, 10)) -
                      new Date(a.updated_at.substr(0, 10))
                    );
                  });
                  return chaburah;
                })
                .sort(function (a, b) {
                  return (
                    new Date(b.orders[0].updated_at.substr(0, 10)) -
                    new Date(a.orders[0].updated_at.substr(0, 10))
                  );
                })
                .map((chaburah) => (
                  <React.Fragment key={chaburah.id}>
                    {chaburah.orders.map((order) => (
                      <TableRow key={order.id}>
                        <TableCell>{chaburah.name}</TableCell>
                        <TableCell>{chaburah.nickname}</TableCell>
                        <TableCell>{order.updated_at.substr(5, 5)}</TableCell>
                        <TableCell>
                          <TotalsPopUp
                            chaburah_id={chaburah.id}
                            order_id={order.id}
                            chaburah_name={chaburah.nickname}
                          />
                        </TableCell>
                        {user.role === "admin" && (
                          <TableCell>
                            <Link
                              to={`/chaburos/${chaburah.id}/orders/${order.id}/payments`}
                              className={classes.runLink}
                            >
                              <Button color="primary" variant="outlined">
                                Payment
                              </Button>
                            </Link>
                          </TableCell>
                        )}
                        <TableCell>
                          <Link
                            to={`/chaburos/${chaburah.id}/orders/${order.id}/runs`}
                            className={classes.runLink}
                          >
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() =>
                                this.handleEndOrder(chaburah.id, order.id)
                              }
                            >
                              Restart
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </Card>
      );
    }
  }
);
