import React from "react";
import { Form, Field } from "react-final-form";
import { TextField, Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import ChaburahSearch from "./ChaburahSearch";
import ChaburahForm from "../components/ChaburahForm";

// this function is needed b/c I don't know how to use react-final-form-arrays yet
// cooerce the amount to zero if it's not defined so that we get a line item that can be updated later
const mapToLineItems = (orderId, lineItems, matzahTypes) =>
  matzahTypes.map((mt, i) => ({
    order_id: orderId,
    amount: Number(lineItems[i] || 0),
    matzah_type_id: mt.id
  }));

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh"
  },
  card: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  startButton: {
    margin: "20px"
  }
});
export default withStyles(styles)(
  class OrderForm extends React.Component {
    state = {
      currentOrder: undefined,
      selectedChaburah: undefined
    };

    handleSubmit = async ({ lineItems }) => {
      try {
        const { selectedChaburah } = this.state;
        const { id: chaburahId } = selectedChaburah;
        const {
          data: [orderId]
        } = await axios.post(`/api/chaburos/${chaburahId}/orders`, {
          status: "RUNNING"
        });
        const mappedLineItems = mapToLineItems(
          orderId,
          lineItems,
          this.props.matzahTypes
        );
        await Promise.all(
          mappedLineItems.map(li =>
            axios.post(
              `/api/chaburos/${chaburahId}/orders/${orderId}/line_items`,
              li
            )
          )
        );
        this.props.setOrders();
        this.setState({ selectedChaburah: undefined });
      } catch (error) {
        // TODO handle error
      }
    };

    handleChaburahSelection = async selectedChaburah => {
      this.setState({ selectedChaburah });
      if (!selectedChaburah) return;
      const { data: orders = [] } = await axios.get(
        `/api/chaburos/${selectedChaburah.id}/orders`
      );
      const { currentYear } = this.props;
      const currentYearOrder = orders.find(o => o.year === currentYear);
      if (!currentYearOrder) {
        return;
      }
      const { data: lineItems } = await axios.get(
        `/api/chaburos/${selectedChaburah.id}/orders/${currentYearOrder.id}`
      );
      this.setState({ currentOrder: { ...currentYearOrder, lineItems } });
    };
    handleEnter = event => {
      const { matzahTypes } = this.props;
      if (
        event.which === 13 &&
        event.target.name !== `lineItems[${matzahTypes.length - 1}]` &&
        event.target.type !== "submit"
      ) {
        event.preventDefault();
      }
    };
    validate = () => {};
    render() {
      const { chaburos, matzahTypes, classes } = this.props;
      const { currentOrder, selectedChaburah } = this.state;
      const initialValues = {
        lineItems: ((currentOrder && currentOrder.lineItems) || []).map(
          li => li.amount
        )
      };

      return (
        <React.Fragment>
          {!selectedChaburah && (
            <React.Fragment>
              <Typography variant="display1" gutterBottom color="textSecondary">
                Start Chaburah
              </Typography>
              <ChaburahSearch
                chaburos={chaburos}
                selectChaburah={this.handleChaburahSelection}
              />
              <br />
              <br />
              Or enter a new chaburah:
              <ChaburahForm
                selectChaburah={this.handleChaburahSelection}
              />{" "}
            </React.Fragment>
          )}
          {selectedChaburah && (
            <React.Fragment>
              <Typography variant="display1" gutterBottom color="textSecondary">
                {selectedChaburah.nickname}
              </Typography>
              <Form
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} onKeyPress={this.handleEnter}>
                    {matzahTypes.map((mt, index) => (
                      <div key={mt.id}>
                        <Field
                          name={`lineItems[${index}]`}
                          render={({ input }) => (
                            <TextField
                              {...input}
                              type="number"
                              autoComplete="off"
                              label={mt.name}
                              placeholder="Amount"
                            />
                          )}
                        />
                      </div>
                    ))}
                    <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      className={classes.startButton}
                    >
                      Start Now
                    </Button>
                  </form>
                )}
              />{" "}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
  }
);
