import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { Card, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        margin: '20px'
    },
    table: {
        width: '90%',
        margin: '0 auto'
    },
    progress: {
        margin: theme.spacing.unit * 2,
    }
});

export default withStyles(styles)(
    class SummariesTable extends Component {
        /* componentDidMount() {
            const { headers, fields } = this.props;
            this.setState({ headers, fields });
        } */

        render() {
            let itemsMap;
            const { headers, fields } = this.props;
            if (fields.length) {
                itemsMap = fields.map((r, i) => {
                    return (
                        <TableRow key={i}>
                            {r.map((c, i) => {
                                return (
                                    <TableCell className="td-print" key={i}>{c}</TableCell>
                                )
                            })}
                        </TableRow>
                    )
                });
            } else {
                itemsMap =
                    <TableRow >
                        <TableCell className="td-print">No info available</TableCell>
                    </TableRow>
            }
            return (
                <div className={`${this.props.classes.table} parentOf-printPage`}>
                    <Card className={`${this.props.classes.card} print-page`}>
                        <Table>
                            <TableHead>
                                <TableRow className={this.props.classes.header}>
                                    {headers.map((c, i) => (
                                        <TableCell className="td-print" key={i}>{c}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {itemsMap}
                            </TableBody>
                        </Table>
                    </Card>
                </div>
            );

        }
    }
)

