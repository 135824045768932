import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

export default withRouter(
  class LogoutLink extends Component {
    handleClick = async () => {
      try {
        await axios.post("/api/logout");
        this.props.onLogout();
        this.props.history.push("/");
      } catch (error) {
        alert("logout unsuccessful. please try again");
      }
    };
    render = () => (
      <a
        style={{ color: "white", textDecoration: "none", cursor: "pointer" }}
        onClick={this.handleClick}
      >
        Logout
      </a>
    );
  }
);
