import React, { Component } from 'react';
import { Button } from '@material-ui/core';

export default class DownloadPrint extends Component {
/* ------ render component like so  ------ */    
/*  <DownloadPrint disabled={true/false} import={this.state.export} /> 
         and this.state.export: { filename: '<filename>.csv', data: {
                    fields<headers(th)>: [], body<data(arrays of td)>: []  }} */
                        
    imports = [];
    download = () => {
        let rows = [];
        rows.push(this.imports.data.fields);
        this.imports.data.body.forEach(row => {
            rows.push(row);
        });

        console.log(rows);
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
        let filename = this.imports.filename || 'export.csv';
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link); // Required for FF

        link.click();
    }
    componentDidUpdate = () => {
        this.imports = this.props.import;
    }
    render() {
        let dB;
        if(this.props.disabled){
            dB = <Button variant="outlined" color="primary" disabled>Download</Button>
        }else{
            dB = <Button variant="outlined" color="primary" onClick={this.download}>
            Download
        </Button>
        }
        return (
            <div className='printBtnContainer'>
                {dB}
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={window.print}
                >
                    Print
                </Button></div>
        )
    }
}