import React from "react";
import { Link } from "react-router-dom";
import {
  withStyles,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
} from "@material-ui/core";

const styles = (theme) => ({
  header: {
    textAlign: "center",
  },
  card: {
    padding: "20px",
  },
  runLink: {
    textDecoration: "none",
  },
});

const OrderTable = ({ orders, classes, user }) => {
  return (
    <Card className={classes.card}>
      <Typography
        variant="display1"
        gutterBottom
        color="textSecondary"
        className={classes.header}
      >
        Open Chaburos
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <h3>Rosh chaburah</h3>
            </TableCell>
            <TableCell>
              <h3>Chaburah name</h3>
            </TableCell>
            <TableCell>
              <h3>In process</h3>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders
            .slice()
            .reverse()
            .map((o) => {
              return (
                <TableRow key={o.id} hover>
                  <TableCell>{o.name}</TableCell>
                  <TableCell>{o.nickname}</TableCell>
                  <TableCell>
                    <Link
                      to={`/chaburos/${o.chaburah_id}/orders/${o.id}/runs`}
                      className={classes.runLink}
                    >
                      <Button color="primary" variant="outlined">
                        Go
                      </Button>
                    </Link>
                  </TableCell>
                  {user.role === "admin" && (
                    <TableCell>
                      <Link
                        to={`/chaburos/${o.chaburah_id}/orders/${o.id}/payments`}
                        className={classes.runLink}
                      >
                        <Button color="primary" variant="outlined">
                          Payment
                        </Button>
                      </Link>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Card>
  );
};

export default withStyles(styles)(OrderTable);
