import React, { Component } from "react";
import YearContext from "../../YearContext";
import axios from "axios";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    withStyles,
} from "@material-ui/core";
import { $format } from '../../../helpers/numberFormat';

const styles = theme => ({
    runLink: {
        textDecoration: "none"
    },
    header: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)'
    },
    footer: {
        color: '#f00',
    }
});

export default withStyles(styles)(
    class ChaburahPaymentsSummaryPage extends Component {
        state = {
            payments: [],
        };

        static contextType = YearContext;

        async componentDidMount() {
            this.setState({ exportReady: false });
            await this.getData();
            this.setState({ exportReady: true });
        };

        async componentDidUpdate(prevProps) {
            if (this.props.year !== prevProps.year) {
                this.setState({ exportReady: false });
                await this.getData();
                this.setState({ exportReady: true });
            }
        }

        getData = async () => {
            const year = this.props.year;
            const [{ data: payments }] = await Promise.all([
                axios.get(`/api/chaburos/${year}/getTotals`)
            ]);
            this.setState({ payments }, () => {
                let mapped = []; //map to export it
                console.log(this.state.payments);
                this.state.payments.sort((a,b)=>a.created_at.localeCompare(b.created_at)).forEach(p => {
                    mapped.push([ p.created_at.substr(5, 5), p.name, p.nickname, '$' + p.amount]);
                });

                let fields = ['Date', 'Name', 'Nickname', 'Total Paid'];
                this.props.setExportableData(fields, mapped, 'payments-summary');
            });
        }

        render() {
            const { classes } = this.props;
            const { payments } = this.state;
            let itemsMap = '';
            if (payments.length) {
                itemsMap = payments.map((r,i) => {
                    return (
                        <TableBody key={i}>
                            <TableRow>
                                <TableCell className="td-print">{r.created_at.substr(5, 5)}</TableCell>
                                <TableCell className="td-print">{r.name}</TableCell>
                                <TableCell className="td-print">{r.nickname}</TableCell>
                                <TableCell className="td-print">{$format.format(r.amount)}</TableCell>
                                
                            </TableRow>
                        </TableBody>
                    )
                });
                /* itemsMap.push(
                    <TableFooter key='foot'>
                        <TableRow className={classes.header}>
                            <TableCell colSpan={7} className={classes.footer}>Total Lbs Paid For: {totalPaid}</TableCell>
                        </TableRow>
                    </TableFooter>
                ) */
            } else {
                itemsMap =
                    <TableBody><TableRow >
                        <TableCell className="td-print">No payments available</TableCell>
                    </TableRow></TableBody>
            }
            return (
                <Card className={`${classes.card} print-page`}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.header}>
                                <TableCell className="td-print">Date</TableCell>
                                <TableCell className="td-print">Name</TableCell>
                                <TableCell className="td-print">Nickname</TableCell>
                                <TableCell className="td-print">Total Paid</TableCell>

                            </TableRow>
                        </TableHead>
                        {itemsMap}
                    </Table>
                </Card>
            );
        }
    }
);
