import React, { Component } from "react";

import {
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    withStyles
} from "@material-ui/core";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 220,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

export default withStyles(styles)(
    class SelectChaburah extends Component {
        state = {
            currentYear: new Date().getFullYear(),
            selectedChabura: ''
        };

        handleChange = (event) => {
            this.setState({ selectedChabura: event.target.value });
        };

        render() {
            const { chaburos, classes } = this.props;
            return (
                <form className={classes.root} autoComplete="off">
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="dropdown">Select Chaburah</InputLabel>
                        <Select
                            value={this.state.selectedChabura}
                            onChange={e => { this.props.handleChange(e);//send info to parent
                            this.handleChange(e)//set value locally
                         }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {chaburos
                                .map(chaburah => (
                                    <MenuItem key={chaburah.id} value={chaburah.id}>{chaburah.nickname}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </form>
            )
        }
    }
)