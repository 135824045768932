import React, { useState, useEffect } from 'react';
import {
	Grid,
	Card,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	TextField,
	Typography,
	Button,
} from '@material-ui/core';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { produce } from 'immer';
import { $format } from '../helpers/numberFormat';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import { green, red } from '@material-ui/core/colors';

function KioskSelfPay(props) {
	const [orders, setOrders] = useState([]);
	const [name, setName] = useState({ firstName: '', lastName: '' });
	const [items, setItems] = useState([]);
	const [selectedChabura, setSelectedChabura] = useState();
	const [intervalState, setIntervalState] = useState();
	const [paymentResult, setPaymentResult] = useState();
	const [lastNameError, setLastNameError] = useState(false);

	const total = React.useMemo(() => {
		return items.reduce((prev, curr) => {
			if (curr.quantity) {
				return prev + curr.price * curr.quantity;
			}
			return prev;
		}, 0);
	}, [items]);

	useEffect(() => {
		Promise.all([axios.get('/api/orders?status=RUNNING')]).then(([{ data: orders }]) => {
			setOrders(orders);
		});
	}, []);

	useEffect(() => {
		const order = orders.find((o) => o.chaburah_id.toString() === selectedChabura);

		if (order) {
			setPaymentResult('');
			axios.get(`/api/orders/getPricesOfOrder?orderId=${order.id}`).then(({ data: items }) => {
				setItems(items.map((i) => ({ ...i, quantity: '' })));
			});
		}
	}, [selectedChabura]);

	useEffect(() => {
		if (paymentResult) {
			clearInterval(intervalState);
			if (paymentResult === 'OK') {
				setItems(items.map((item) => ({ ...item, quantity: '' })));
				setName({ firstName: '', lastName: '' });
			}
		}
	}, [paymentResult]);

	const onSubmitPayment = async () => {
		const order = orders.find((o) => o.chaburah_id.toString() === selectedChabura);

		const payment_items = items.map((matzahType, index) => ({
			amount: matzahType.quantity || 0,
			matzah_type_id: matzahType.id,
		}));
		const data = {
			orderId: order?.id,
			chaburaId: selectedChabura,
			last_name: name.lastName,
			discount: 0,
			amount: total,
			payment_items,
		};
		setPaymentResult('');

		localStorage.setItem('payment_items', JSON.stringify(data));
		setIntervalState(
			setInterval(() => {
				const item = localStorage.getItem('paymentResult');
				if (item) {
					setPaymentResult(item);
					localStorage.removeItem('paymentResult');
				}
			}, 1000),
		);
	};

	const buildParams = () => {
		const totalWithFee = (total + total * 0.03).toFixed(2);
		const dataParameter = {
			amount_money: {
				amount: totalWithFee * 100,
				currency_code: 'USD',
			},

			// Replace this value with your application's callback URL
			callback_url: 'https://app.lakewoodmatzoh.com/processPayment',
			// Replace this value with your application's ID
			client_id: 'sq0idp-2j9olwue9H3hoHk6DDDkog',

			version: '1.3',
			notes: `${name?.firstName || ''} ${
				name?.lastName || ''
			} placed an order in the amount of ${total}`,
			options: {
				supported_tender_types: ['CREDIT_CARD'],
			},
		};

		return dataParameter;
	};

	return (
		<React.Fragment>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 32,
					alignItems: 'center',
					marginTop: 100,
				}}
			>
				<FormControl>
					<FormLabel>Chaburos</FormLabel>
					<RadioGroup
						row
						value={selectedChabura}
						onChange={(e, value) => {
							setSelectedChabura(value);
						}}
					>
						{orders.map((o) => (
							<FormControlLabel
								key={o.chaburah_id}
								value={o.chaburah_id.toString()}
								control={<Radio />}
								label={o.nickname}
							/>
						))}
					</RadioGroup>
				</FormControl>
				<div style={{ display: 'flex', gap: 8 }}>
					<TextField
						label='First Name'
						value={name.firstName}
						variant='outlined'
						onChange={(e) => {
							setName(
								produce(name, (draft) => {
									draft.firstName = e.target.value;
								}),
							);
						}}
					/>
					<TextField
						label='Last Name *'
						error={!name.lastName}
						value={name.lastName}
						variant='outlined'
						onChange={(e) => {
							setName(
								produce(name, (draft) => {
									draft.lastName = e.target.value;
								}),
							);
						}}
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
					{items.map((mt, index) => (
						<div key={mt.matzahtype} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
							<Typography style={{ width: 75, fontWeight: 'bold' }}>{mt.matzahtype}</Typography>
							<Typography style={{ width: 115 }}>${mt.price} Per Pound</Typography>
							<TextField
								id='outlined-basic'
								variant='outlined'
								type='number'
								size='small'
								value={mt.quantity}
								onChange={(e) => {
									setPaymentResult('');
									setItems(
										produce(items, (draft) => {
											draft[index].quantity = e.target.value;
										}),
									);
								}}
							/>
							<div>LBS</div>
						</div>
					))}
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 16, justifyContent: 'center' }}>
					<div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
						<div style={{ marginTop: 16, display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
							<Typography style={{ width: 75 }}>Subtotal:</Typography>
							<Typography style={{ width: 75 }}>{$format.format(total)}</Typography>
						</div>
						<div style={{ display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
							<Typography style={{ width: 75 }}>CC 3%: </Typography>
							<Typography style={{ width: 75 }}>{$format.format(total * 0.03)}</Typography>
						</div>
						<div style={{ marginTop: 16, display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
							<Typography style={{ width: 75 }}>Total:</Typography>
							<Typography style={{ width: 75 }}>
								{$format.format(total + total * 0.03)}
							</Typography>
						</div>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							gap: 16,
						}}
					>
						{name.lastName && total > 0 ? (
							<a
								onClick={onSubmitPayment}
								href={`square-commerce-v1://payment/create?data=${encodeURIComponent(
									JSON.stringify(buildParams()),
								)} `}
							>
								<Button
									className={props.classes.button}
									variant='contained'
									size='large'
									color='primary'
									disabled={!name.lastName || total <= 0}
								>
									Pay with Square
								</Button>
							</a>
						) : (
							<Button
								className={props.classes.button}
								variant='contained'
								size='large'
								color='primary'
								disabled={true}
							>
								Pay with Square
							</Button>
						)}
						{lastNameError && <div style={{ color: 'red' }}>Please Enter Name</div>}
						{paymentResult === 'OK' ? (
							<CheckCircleOutlineIcon style={{ color: green[500] }} />
						) : paymentResult ? (
							<ClearIcon style={{ color: red[500] }} />
						) : (
							<div />
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

const styles = (theme) => ({
	root: {
		minHeight: '100vh',
		margin: '100px auto',
	},
	orderCard: {
		height: '420px',
		padding: '20px',
		textAlign: 'center',
	},
	button: {
		height: '50px',
	},
});

export default withStyles(styles)(KioskSelfPay);
