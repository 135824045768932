import React, { Component } from "react";
import { Card, withStyles, Button } from "@material-ui/core";
import Dropdown from "./Dropdown";
import DateChooser from "./DateChooser";
const styles = theme => ({
  runLink: {
    textDecoration: "none"
  },
  sortCard: {
    height: "350px",
    textAlign: "center",
    paddingTop: "50px",
    marginTop: "50px"
  },
  resetButton: {
    margin: "20px"
  },
  dropdown: {
    marginLeft: "10px",
    marginRight: "10px"
  }
});

export default withStyles(styles)(
  class ChaburahFilter extends Component {
    state = {
      currentYear: new Date().getFullYear(),
      chaburos: []
    };
    componentDidMount() {
      this.setState({
        chaburos: this.props.chaburos
      });
    }
    componentDidUpdate(prevProps) {
      if (prevProps.chaburos !== this.props.chaburos) {
        this.setState({
          chaburos: this.props.chaburos
        });
      }
    }
    sortByDateAndName = (selectedDate, sortedByName) => {
      return sortedByName
        .map(chaburo => {
          return {
            ...chaburo,
            orders: chaburo.orders.filter(
              order => order.updated_at.substr(0, 10) === selectedDate
            )
          };
        })
        .filter(chaburo => chaburo.orders.length > 0);
    };
    sortByDate = selectedDate => {
      return this.props.chaburos
        .map(chaburo => {
          return {
            ...chaburo,
            orders: chaburo.orders.filter(
              order => order.updated_at.substr(0, 10) === selectedDate
            )
          };
        })
        .filter(chaburo => chaburo.orders.length > 0);
    };
    sortByName = selectedChaburah => {
      return this.state.chaburos.filter(
        chaburo =>
          chaburo.name === selectedChaburah.name &&
          chaburo.nickname === selectedChaburah.nickname
      );
    };
    selectedChaburah = selectedChaburah => {
      this.setState({ selectedChaburah: selectedChaburah });
    };
    selectedDate = selectedDate => {
      this.setState({ selectedDate: selectedDate.target.value });
    };
    sort = () => {
      if (this.state.selectedDate && this.state.selectedChaburah) {
        var sortedByName = this.sortByName(this.state.selectedChaburah);
        var sortedByDate = this.sortByDateAndName(
          this.state.selectedDate,
          sortedByName
        );
        this.props.stateSetter(sortedByDate);
      } else if (this.state.selectedChaburah) {
        this.props.stateSetter(this.sortByName(this.state.selectedChaburah));
      } else if (this.state.selectedDate) {
        this.props.stateSetter(this.sortByDate(this.state.selectedDate));
      }
    };

    render() {
      const { classes } = this.props;

      return (
        <Card className={classes.sortCard}>
          Sort by date and/or name:
          <Dropdown
            values={this.props.chaburos}
            placeholder="Sort by name"
            handleChange={this.selectedChaburah}
          />
          <DateChooser
            className={classes.resetButton}
            chaburos={this.props.chaburos}
            selectedDate={this.selectedDate}
          />{" "}
          <Button
            className={classes.resetButton}
            color="primary"
            variant="outlined"
            onClick={() => this.sort()}
          >
            Filter
          </Button>{" "}
          <Button
            className={classes.resetButton}
            color="primary"
            variant="outlined"
            onClick={() => this.props.stateSetter(this.props.chaburos)}
          >
            Reset
          </Button>{" "}
        </Card>
      );
    }
  }
);
