import React, { Component } from 'react';
import { withStyles, TextField } from '@material-ui/core';
import axios from 'axios';
import YearContext from "../../YearContext";
import filterMenu from '../../../helpers/searchChaburah';
import { Card, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import SummariesTableComponent from './SummariesTableComponent';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        margin: '20px'
    },
    table: {
        width: '95%',
        margin: '0 auto'
    },
    progress: {
        margin: theme.spacing.unit * 2,
    }
});

export default withStyles(styles)(
    class ChaburahRunsSummary extends Component {
        state = {
            headers: [],
            matzahTypes: [],
            filterdChaburos: [],
            mapped: [],
            currentYear: new Date().getFullYear(),
            export: {
                filename: '', data: {
                    body: [], fields: []
                }
            }
        }

        async componentDidMount() {
            this.setState({ exportReady: false });
            await this.getChaburos();
            this.setState({ exportReady: true });
        };

        async componentDidUpdate(prevProps) {
            if (this.props.year !== prevProps.year) {
                this.setState({ exportReady: false });
                await this.getChaburos();
                this.setState({ exportReady: true });
            }
        }

        static contextType = YearContext;

        async getChaburos() {
            const mapped = [];
            const year = this.props.year;
            const [{ data: chaburos }, { data: matzahTypes }] = await Promise.all([
                axios.get(`/api/chaburos/${year}/getAll`),
                axios.get("/api/matzah_types")
            ]);
            this.setState({ matzahTypes, headers: ['Rosh chaburah', 'Chaburah name', 'Completed On', 'Tables', ...matzahTypes.map(mt => mt.name)] });
            const [{ data: runItems }] = await Promise.all([
                axios.get(`/api/chaburos/${year}/getAllRuns`)
            ]);

            chaburos.forEach(c => {
                const matzoTypes = [];
                const filteredRunItems = runItems.filter(ri => ri.id === c.id);
                filteredRunItems.forEach(ri => {
                    const mtid = +ri.matzah_type_id - 1;//fill array from 0
                    matzoTypes[mtid] ? matzoTypes[mtid] += ri.amount : matzoTypes[mtid] = ri.amount;
                });
                mapped.push([c.roshname, c.name, c.created_at.substr(5, 5), c.tables, ...matzoTypes]);

            });
            this.setState({ currentYear: year, filterdChaburos: mapped, mapped });
            console.log(mapped);
             
            this.props.setExportableData(this.state.headers, mapped, `allRuns-${year}`);

        }

        chaburahSearch = (e) => {
            let filtered = filterMenu(this.state.mapped, e.target.value);
            this.setState({ filterdChaburos: filtered });
        }


        render() {
            return (
                <div
                    justify="center"
                    className={this.props.classes.root}
                >
                    <div className={`noPrint ${this.props.classes.input}`}>
                        <TextField id="filled-basic" label="Search Chaburah" variant="filled" onChange={e => this.chaburahSearch(e)}
                        />
                    </div>
                    <SummariesTableComponent headers={this.state.headers} fields={this.state.filterdChaburos} />
                </div> 
            );

        }
    }
)

