import React, { Component } from "react";
import PaymentFormPopUp from "./PaymentFormPopUp";
import EditPricesPopUp from "./EditPricesPopUp";
import {
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  withStyles,
} from "@material-ui/core";
const styles = (theme) => ({
  card: {
    padding: "20px",
  },
  headerRow: {
    color: "red",
  },
  owed: {
    color: "orange",
  },
});
export default withStyles(styles)(
  class ChaburahPaymentInfoTable extends Component {
    render() {
      const {
        classes,
        payments,
        prices,
        matzahTypes,
        chaburah,
        order_id,
        setPrices,
        setPayments,
        totalOwedOnItems,
        // totalOwed,
        totals,
        lineItems,
      } = this.props;
      const {
        totalPaidOnItems,
        // totalPaid,
        outstandingOnItems,
        outstanding,
        amountDiscounted,
        outstandingOnMatzah,
        // amountPaid
      } = this.props.chaburahPaymentInfoTableInfo;
      console.log("matzahTypes");
      console.log(matzahTypes);
      return (
        <Card className={classes.card}>
          <Typography
            align="center"
            variant="display1"
            gutterBottom
            color="textSecondary"
            className={classes.header}
          >
            Payment Info For {this.props.chaburah.nickname}
          </Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <PaymentFormPopUp
                    payments={payments}
                    matzahTypes={matzahTypes}
                    chaburah={chaburah}
                    order_id={order_id}
                    prices={prices}
                    setPayments={setPayments}
                  />{" "}
                  {!payments.length && (
                    <EditPricesPopUp
                      chaburah={chaburah}
                      order_id={order_id}
                      matzahTypes={matzahTypes}
                      prices={prices}
                      setPrices={setPrices}
                      totals={totals}
                      lineItems={lineItems}
                    ></EditPricesPopUp>
                  )}
                </TableCell>
                <TableCell className={classes.headerRow}>
                  Matzoh baked
                </TableCell>
                {!!totalPaidOnItems && (
                  <TableCell className={classes.headerRow}>
                    Matzoh outstanding
                  </TableCell>
                )}
                <TableCell className={classes.headerRow}>Total due</TableCell>{" "}
                {!!totalPaidOnItems && (
                  <React.Fragment>
                    <TableCell className={classes.headerRow}>Paid</TableCell>
                    <TableCell className={classes.headerRow}>Balance</TableCell>
                  </React.Fragment>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {matzahTypes.map((matzahType, i) => {
                return prices.find((p) => p.matzah_type_id === matzahType.id)
                  .price === 0 ? null : (
                  <TableRow key={matzahType.id}>
                    <TableCell className={classes.headerRow}>
                      {matzahType.name}
                    </TableCell>
                    <TableCell>{totals[i].amount}</TableCell>
                    {!!totalPaidOnItems && (
                      <TableCell>{outstandingOnMatzah[i].amount}</TableCell>
                    )}
                    <TableCell>{"$" + totalOwedOnItems[i].amount}</TableCell>
                    {!!totalPaidOnItems && (
                      <React.Fragment>
                        <TableCell>
                          {"$" + totalPaidOnItems[i].amount}
                        </TableCell>
                        <TableCell>
                          {"$" + outstandingOnItems[i].amount}
                        </TableCell>
                      </React.Fragment>
                    )}
                  </TableRow>
                );
              })}
              {!!totalPaidOnItems && (
                <React.Fragment>
                  <TableRow>
                    <TableCell className={classes.headerRow}>
                      DISCOUNT
                    </TableCell>
                    <TableCell>{"$" + amountDiscounted}</TableCell>
                    <TableCell />
                    <TableCell></TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.headerRow}>
                      TOTAL OUTSTANDING
                    </TableCell>
                    <TableCell>XXXXXXXX</TableCell>
                    <TableCell>XXXXXXXX</TableCell>
                    <TableCell>XXXXXXXX</TableCell>
                    <TableCell>{"$" + outstanding}</TableCell>
                  </TableRow>
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </Card>
      );
    }
  }
);
