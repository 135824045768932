import React, { Component } from 'react';
import { withStyles, TextField } from '@material-ui/core';
import axios from 'axios';
import YearContext from "../../YearContext";
// import filterMenu from '../../../helpers/searchChaburah';
import SummariesTable from './SummariesTableComponent';
import { $format } from '../../../helpers/numberFormat';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        margin: '20px'
    },
    table: {
        width: '85%',
        margin: '0 auto'
    },
    progress: {
        margin: theme.spacing.unit * 2,
    }
});

export default withStyles(styles)(
    class ChaburahTotals extends Component {
        state = {
            // filterdChaburos: [],
            headers: [],
            rows: [],
            currentYear: new Date().getFullYear(),
            export: {
                filename: '', data: {
                    fields: [], body: []
                }
            }
        }

        async componentDidMount() {
            this.setState({ exportReady: false });
            await this.getData();
            this.setState({ exportReady: true });
        };

        async componentDidUpdate(prevProps) {
            if (this.props.year !== prevProps.year) {
                this.setState({ exportReady: false });
                await this.getData();
                this.setState({ exportReady: true });
            }
        }

        static contextType = YearContext;

        async getData() {

            this.setState({ headers: ['Rosh chaburah', 'Chaburah name', 'Date', 'Tables', 'Total Regular', 'Regular Price', 'Total Rosh', 'Rosh Price', 'Avg Lbs Per Table'] });

            const year = this.props.year;
            try {
                let mappedData = [];
                const [{ data }] = await Promise.all([
                    axios.get(`/api/chaburos/${year}/getChaburahTotals`),
                ]);

                data.forEach(e => {
                    let reg_price = +e.reg_price > 0 ? e.reg_price : e.reg_ww_price;
                    let rosh_price = +e.rosh_price > 0 ? e.rosh_price : e.rosh_ww_price;
                    let avg = ((e.reg + e.rosh) / (+e.tables || 1)).toFixed(2);
                    mappedData.push([e.name, e.nickname, e.date.substr(5,5), e.tables, e.reg, $format.format(reg_price), e.rosh, $format.format(rosh_price), avg]);
                });
                this.setState({ currentYear: year, rows: mappedData });

                this.props.setExportableData(this.state.headers, mappedData, `Totals-${year}`);
            } catch (err) {
                console.log(err);
            }
        }

        /* chaburahSearch = (e) => {
            let filtered = filterMenu(this.state.mapped, e.target.value);
            this.setState({ filterdChaburos: filtered });
        }
        */

        render() {
            return (
                <div
                    justify="center"
                    className={this.props.classes.root}
                >
                    {/* <div className={`noPrint ${this.props.classes.input}`}>
                        <TextField id="filled-basic" label="Search Chaburah" variant="filled" onChange={e => this.chaburahSearch(e)}
                        />
                    </div> */}
                    <SummariesTable headers={this.state.headers} fields={this.state.rows} />
                </div>

            );

        }
    }
)

