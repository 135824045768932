import React, { Component } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import axios from 'axios';
import DownloadPrint from '../DownloadPrint';
import YearContext from "../YearContext";
import ChaburahRunsPage from './ChaburaRunsPage';
import ChaburahPaymentsPage from './ChaburahPaymentsPage';
import SelectChaburah from './SelectChaburah';


const styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        margin: '20px'
    },
    table: {
        width: '80%',
        margin: '0 auto'
    }
});

export default withStyles(styles)(
    class ChaburahListReports extends Component {
        state = {
            chaburos: [],
            currentYear: new Date().getFullYear(),
            export: {
                filename: '', data: {
                    body: [], fields: []
                }
            },
            exportReady: false
        }

        async componentDidMount() {
            this.setState({ exportReady: false });
            await this.getChaburos();
        };

        async componentDidUpdate(prevProps) {
            if (this.props.currentYear !== prevProps.currentYear) {
                this.setState({ exportReady: false });
                await this.getChaburos();
            }
        }

        static contextType = YearContext;

        async getChaburos() {
            const year = this.props.currentYear;
            const [{ data: chaburos }] = await Promise.all([
                axios.get(
                    "/api/chaburos?include=orders&year=" +
                    year +
                    "&where=orders.status&is=COMPLETED"
                ),
            ]);
            let sorted = chaburos.sort((a, b) => a.name.localeCompare(b.name));
            this.setState({ currentYear: year, chaburos: sorted });
        }

        setExportableData = (fields, chaburah, mapped, report) => {  
            this.setState({ export: { filename: `ChaburahId-${chaburah}-${report}.csv`, data: { body: mapped, fields } } });
            this.setState({ exportReady: true });
        };

        handleChange = (event) => {
            this.setState({ selectedChabura: event.target.value });
        };


        render() {
            let download;
            if (this.state.exportReady) {
                download = <DownloadPrint  diasabled='false' import={this.state.export} />
            } else {
                download = <DownloadPrint disabled='true' import={this.state.export} />
            }

            let showPage;
            const {pathname} = this.props.location;
            if(pathname.includes('Runs')){
                showPage = <ChaburahRunsPage chaburah={this.state.selectedChabura} setExportableData={this.setExportableData} />
            }else if(pathname.includes('Payments')){
                showPage = <ChaburahPaymentsPage chaburah={this.state.selectedChabura} setExportableData={this.setExportableData} />
            }
            return (
                <div justify="center" className={this.props.classes.root}>
                    <br />
                    <Grid container 
                        justify="space-around"
                        alignItems="center"
                    >
                        <Grid item>
                            <SelectChaburah chaburos={this.state.chaburos} handleChange={this.handleChange} />
                        </Grid>
                        <Grid item className='noPrint'>
                            {download}
                        </Grid>
                    </Grid>
                    <br />
                    <div className={this.props.classes.table}>
                        {showPage}
                    </div>
                </div>
            )
        }
    }
)

