import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: '156e92ce9a2d4ef5b6ff39425258bb03',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['staging', 'production'],
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);
