import React from "react";
import { Form, Field } from "react-final-form";
import { TextField, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";

const styles = {};

export default withStyles(styles)(
  class ChaburahForm extends React.Component {
    handleSubmit = async ({ name, title }) => {
      try {
        const { data: chaburahId } = await axios.post("/api/chaburos", {
          name,
          title
        });
        const { data: newChaburah } = await axios.get(
          "/api/chaburos/" + chaburahId
        );
        this.props.selectChaburah(newChaburah[0]);
      } catch (error) {
        alert("something went wrong", error);
      }
    };

    validate = () => {};
    render() {
      return (
        <React.Fragment>
          <Form
            onSubmit={this.handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field
                    name="name"
                    render={({ input }) => (
                      <TextField
                        {...input}
                        autoComplete="off"
                        label="Rosh Chaburah"
                        placeholder="Rosh Chaburah"
                        required
                      />
                    )}
                  />
                </div>
                <div>
                  <Field
                    name="title"
                    render={({ input }) => (
                      <TextField
                        {...input}
                        autoComplete="off"
                        label="Chaburah name"
                        placeholder="Chaburah name"
                        required
                      />
                    )}
                  />
                </div>
                <br />
                <br />
                <Button color="secondary" variant="contained" type="submit">
                  Next
                </Button>
              </form>
            )}
          />
        </React.Fragment>
      );
    }
  }
);
