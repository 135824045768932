import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, withStyles } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import LogoutLink from './LogoutLink';
import YearPicker from './YearPicker';

const styles = (theme) => ({
	flex: {
		flex: 1,
	},
	welcomeText: {
		marginRight: '20px',
	},
	icon: {
		margin: '5px',
	},
	link: {
		margin: '0px 10px',
		color: 'white',
		textDecoration: 'none',
	},
});

export default withStyles(styles)(({ user, onLogout, classes, setYear }) => {
	if (
		window.location.pathname.toLowerCase().includes('kiosk') ||
		window.location.pathname.toLowerCase().includes('processpayment')
	) {
		return <div></div>;
	}
	return (
		<AppBar position='static'>
			<Toolbar>
				<YearPicker setYear={setYear} />

				<Typography variant='title' color='inherit' className={classes.flex}>
					<Link to='/orders' className={classes.link}>
						Active
					</Link>
					<Link to='/chaburos' className={classes.link}>
						Complete
					</Link>
					<Link to='/reports' className={classes.link}>
						Reports
					</Link>
				</Typography>
				{user && (
					<React.Fragment>
						<AccountCircle className={classes.icon} />
						<Typography variant='subheading' color='inherit' className={classes.welcomeText}>
							{user.name}
						</Typography>
					</React.Fragment>
				)}
				{user && (
					<React.Fragment>
						<ExitToApp className={classes.icon} />
						<Typography variant='subheading' color='inherit' className={classes.welcomeText}>
							<LogoutLink style={{ color: 'white' }} onLogout={onLogout} />
						</Typography>
					</React.Fragment>
				)}
			</Toolbar>
		</AppBar>
	);
});
