import React, { Component } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions
} from "@material-ui/core";

export default class UpdateChabuahInfo extends Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    const sortedLineItems = this.props.sortedLineItems;
    this.props.matzahTypes.map((matzahType, index) =>
      this.setState({
        [matzahType.name]: (sortedLineItems[index] || {amount: 0}).amount
      })
    );
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = async event => {
    this.setState({ open: false });
    const sortedLineItems = this.props.sortedLineItems;
    await Promise.all(
      sortedLineItems.map((li, index) => {
        const id = li.id;
        const amount = this.state[this.props.matzahTypes[index].name] || 0;
        const orderId = li.order_id;
        const chaburahId = this.props.chaburah.id;
        return axios.put(
          `/api/chaburos/${chaburahId}/orders/${orderId}/line_items/${id}`,
          { amount }
        );
      })
    );
    this.props.setNewState();
  };

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };
  render() {
    return (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen}
        >
          Update
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle id="form-dialog-title">
            Update {this.props.chaburah.nickname} order info
          </DialogTitle>
          <DialogContent>
            {this.props.matzahTypes.map(matzahType => (
              <TextField
                key={matzahType.id}
                autoFocus
                name={matzahType.name}
                margin="dense"
                label={matzahType.name}
                type="number"
                value={this.state[matzahType.name]}
                onChange={this.handleChange}
                fullWidth
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
