import React from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import RunForm from "../components/RunForm";
import RunTable from "../components/RunTable";

const styles = theme => ({
  root: {
    minHeight: "100vh",
    margin: "100px auto"
  }
});

export default withStyles(styles)(
  class OrderPage extends React.Component {
    state = {
      chaburah: {},
      matzahTypes: [],
      lineItems: [],
      runs: []
    };
    async componentDidMount() {
      const { chaburah_id, order_id } = this.props.match.params;
      const [
        {
          data: [chaburah]
        },
        { data: matzahTypes },
        { data: lineItems },
        { data: runs }
      ] = await Promise.all([
        axios.get(`/api/chaburos/${chaburah_id}`),
        axios.get("/api/matzah_types"),
        axios.get(`/api/chaburos/${chaburah_id}/orders/${order_id}/line_items`),
        axios.get(`/api/chaburos/${chaburah_id}/orders/${order_id}/runs`)
      ]);
      this.setState({ chaburah, matzahTypes, lineItems, runs });
    }
    setNewState = async () => {
      const { chaburah_id, order_id } = this.props.match.params;
      const { data: lineItems } = await axios.get(
        `/api/chaburos/${chaburah_id}/orders/${order_id}/line_items`
      );
      this.setState({ lineItems });
    };
    setUpdatedRun = async () => {
      const { chaburah_id, order_id } = this.props.match.params;
      const { data: runs } = await axios.get(
        `/api/chaburos/${chaburah_id}/orders/${order_id}/runs`
      );
      this.setState({ runs });
    };

    handleEndOrder = async () => {
      const { chaburah_id, order_id } = this.props.match.params;
      try {
        await axios.put(`/api/chaburos/${chaburah_id}/orders/${order_id}`, {
          status: "COMPLETED"
        });
        this.props.history.push(`/orders`);
      } catch (error) {
        alert("an error occured");
        console.log(error);
      }
    };
    render() {
      const {
        classes,
        match: {
          params: { order_id }
        }
      } = this.props;
      const { chaburah, matzahTypes, lineItems, runs } = this.state;
      return (
        <Grid container justify="center" spacing={16} className={classes.root}>
          <Grid item sm={3}>
            <RunForm
              matzahTypes={matzahTypes}
              chaburah={chaburah}
              order_id={order_id}
              currentRunNum={runs.length + 1}
              onSuccessfulSubmit={this.setUpdatedRun}
            />
          </Grid>
          <Grid item sm={7}>
            <RunTable
              chaburah={chaburah}
              matzahTypes={matzahTypes}
              runs={runs}
              lineItems={lineItems}
              handleEndOrder={this.handleEndOrder}
              setNewState={this.setNewState}
              setUpdatedRun={this.setUpdatedRun}
            />
          </Grid>
        </Grid>
      );
    }
  }
);
