import React from "react";
import { Route, Redirect } from "react-router-dom";
export default ({
  component: Component,
  isAuthenticated,
  isAuthorised,
  ...rest
}) => (

  <Route
    {...rest}
    render={props =>
      isAuthenticated && isAuthorised ? (
        <Component {...props} {...rest} />
      ) : isAuthenticated && !isAuthorised ? (
        <span>not authorised</span>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
