import React, { Component } from "react";

import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Card
} from "@material-ui/core";
import { getTotals } from "../helpers/getTotals";

const styles = theme => ({
  card: {
    padding: "40px"
  }
});
const CustomCell = withStyles(theme => ({
  root: {
    paddingRight: "24px"
  }
}))(TableCell);
export default withStyles(styles)(
  class TotalsTable extends Component {
    state = {
      chaburah: [],
      lineItems: [],
      runs: [],
      totals: [],
      sortedLineItems: [],
      matzahTypes: [],
      totalRemaining: []
    };

    async componentDidMount() {
      const results = await getTotals(
        this.props.chaburah.id,
        this.props.order_id
      );
      this.setState(() => results);
    }

    render() {
      const { classes } = this.props;

      return (
        <Card className={classes.card}>
          <Typography
            variant="display1"
            align="center"
            gutterBottom
            color="textSecondary"
          >
            Totals
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <CustomCell />
                {this.state.matzahTypes.map(mt => (
                  <CustomCell key={mt.id}>{mt.name}</CustomCell>
                ))}
              </TableRow>
              <TableRow>
                <CustomCell>Ordered</CustomCell>

                {this.state.sortedLineItems.map(li => (
                  <CustomCell key={li.id}>{li.amount}</CustomCell>
                ))}
              </TableRow>
              <TableRow>
                <CustomCell>Packed</CustomCell>
                {this.state.totals.map(t => (
                  <CustomCell key={t.id}>{t.amount}</CustomCell>
                ))}
              </TableRow>
              <TableRow>
                <CustomCell>Remaining</CustomCell>
                {this.state.totalRemaining.map(t => (
                  <CustomCell key={t.id}>{t.amount}</CustomCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      );
    }
  }
);
