import React, { Component } from "react";
import {
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
} from "@material-ui/core";

import PrintThis from "./PrintThis";
import PrintReport from "./PrintReport";
import EditPaymentPopUp from "./EditPaymentPopup";
const styles = (theme) => ({
  card: {
    marginTop: "20px",
  },
  headerRow: {
    // textAlign: "center",
    color: "red",
  },
  PaymentsTable: {
    maxHeight: "200px",
    overflow: "auto",
  },
});

export default withStyles(styles)(
  class PaymentsTable extends Component {
    state = {};
    render() {
      const {
        classes,
        payments,
        matzahTypes,
        chaburah,
        order_id,
        prices,
        setPaymentsAndGetTableData,
      } = this.props;

      return (
        <Card className={classes.card}>
          <Typography
            align="center"
            variant="title"
            color="textSecondary"
            className={classes.header}
          >
            Payers Info
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.headerRow}>
                  <PrintReport
                    chaburah_id={this.props.chaburah_id}
                    order_id={this.props.order_id}
                    payments={this.props.payments}
                    prices={this.props.prices}
                    chaburah={this.props.chaburah}
                    matzahTypes={matzahTypes}
                    totalOwedOnItems={this.props.totalOwedOnItems}
                    totalOwed={this.props.totalOwed}
                    chaburahPaymentInfoTableInfo={
                      this.props.chaburahPaymentInfoTableInfo
                    }
                    totals={this.props.totals}
                  ></PrintReport>
                </TableCell>
                <TableCell className={classes.headerRow}>Last </TableCell>
                <TableCell className={classes.headerRow}>Paid</TableCell>
                <TableCell className={classes.headerRow}>Edit</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className={classes.PaymentsTable}>
            <Table>
              <TableBody>
                {payments
                  .sort(function (a, b) {
                    return b.id - a.id;
                  })
                  .map((payment) => (
                    <TableRow key={payment.id}>
                      <TableCell>
                        <PrintThis
                          matzohTaken={payment.payment_items}
                          amountPaid={payment.payment_amount}
                          matzahTypes={matzahTypes}
                        />
                      </TableCell>
                      <TableCell>{payment.last_name}</TableCell>
                      <TableCell>{"$" + payment.payment_amount}</TableCell>
                      <EditPaymentPopUp
                        payment={payment}
                        payments={payments}
                        matzahTypes={matzahTypes}
                        chaburah={chaburah}
                        order_id={order_id}
                        prices={prices}
                        setPaymentsAndGetTableData={setPaymentsAndGetTableData}
                      />
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </Card>
      );
    }
  }
);
