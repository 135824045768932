import React from 'react';
import {
	withStyles,
	Card,
	Button,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
} from '@material-ui/core';

import Update from './UpDateChaburahInfo';
import UpdateRun from './UpDateRunInfo';
const styles = (theme) => ({
	header: {
		textAlign: 'center',
	},
	headerRow: {
		textAlign: 'center',
		[theme.breakpoints.down('lg')]: {
			fontSize: 15,
		},
		fontSize: '20px',
		color: 'red',
	},
	card: {
		padding: '20px',
	},
	endButton: {
		float: 'right',
	},
	stopIcon: {
		marginLeft: '10px',
	},
	remaining: {
		color: 'orange',
		fontSize: 'large',
	},
	runRow: {
		maxHeight: '300px',
		overflow: 'auto',
	},
});

const makeSortByMatzahType = (matzahTypes) => (a, b) =>
	matzahTypes.findIndex((mt) => mt.id === a.matzah_type_id) -
	matzahTypes.findIndex((mt) => mt.id === b.matzah_type_id);

const getTotalPacked = (runs) => {
	return runs
		.reduce((acc, run) => [...acc, ...run.run_items], [])
		.reduce(
			(totals, runItem) => ({
				...totals,
				[runItem.matzah_type_id]: (totals[runItem.matzah_type_id] || 0) + (runItem.amount || 0),
			}),
			{},
		);
};

const getTotalRemaining = (lineItems, totalPacked) =>
	lineItems.map((li) => {
		const mt = totalPacked.find((mt) => mt.id === li.matzah_type_id);
		return {
			...mt,
			amount: (li.amount || 0) - (mt.amount || 0),
		};
	});

export default withStyles(styles)(function RunTable({
	chaburah,
	matzahTypes,
	runs,
	lineItems,
	handleEndOrder,
	classes,
	setNewState,
	setUpdatedRun,
}) {
	const totalsObj = getTotalPacked(runs);
	const totals = matzahTypes.map((mt) => ({
		...mt,
		amount: totalsObj[mt.id] || 0,
	}));
	const sortByMatzahType = makeSortByMatzahType(matzahTypes);
	const sortedLineItems = lineItems.slice().sort(sortByMatzahType);
	const totalRemaining = getTotalRemaining(sortedLineItems, totals);
	return (
		<Card className={classes.card}>
			<Typography variant='display1' gutterBottom color='textSecondary' className={classes.header}>
				Table Totals
				<Button
					color='secondary'
					variant='contained'
					size='large'
					onClick={handleEndOrder}
					className={classes.endButton}
				>
					End Chaburah
				</Button>
			</Typography>
			<Table>
				<TableHead>
					<TableRow>
						<CustomCell>
							<Update
								chaburah={chaburah}
								sortedLineItems={sortedLineItems}
								matzahTypes={matzahTypes}
								setNewState={setNewState}
							/>
						</CustomCell>

						{matzahTypes.map((mt) => (
							<CustomCell key={mt.id} className={classes.headerRow}>
								{mt.name}
							</CustomCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<CustomCell>Ordered</CustomCell>

						{sortedLineItems.map((li) => (
							<CustomCell key={li.id}>{li.amount}</CustomCell>
						))}
					</TableRow>
					<TableRow>
						<CustomCell>Packed</CustomCell>
						{totals.map((t) => (
							<CustomCell key={t.id}>{t.amount}</CustomCell>
						))}
					</TableRow>
					<TableRow>
						<CustomCell className={classes.remaining}>Remaining</CustomCell>
						{totalRemaining.map((t) => (
							<CustomCell key={t.id} className={classes.remaining}>
								{t.amount}
							</CustomCell>
						))}
					</TableRow>
					<TableRow />
					{runs
						.slice()
						.sort(function (a, b) {
							return b.id - a.id;
						})

						.map((run, index) => (
							<TableRow key={`run-${run.id}`}>
								<CustomCell key={run.id}>
									<UpdateRun
										matzahTypes={matzahTypes}
										run={run}
										setUpdatedRun={setUpdatedRun}
										runNumber={Math.abs(index - runs.length)}
									/>
								</CustomCell>
								{run.run_items
									.slice()
									.sort(sortByMatzahType)
									.map((ri) => (
										<CustomCell key={ri.id}>{ri.amount}</CustomCell>
									))}
							</TableRow>
						))}
				</TableBody>
			</Table>
		</Card>
	);
});

const CustomCell = withStyles((theme) => ({
	root: {
		[theme.breakpoints.down('lg')]: {
			paddingRight: '23px',
			textAlign: 'center',
		},
	},
}))(TableCell);
